import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enUS from "./enUS.json";
import frFR from "./frFR.json";
import esES from "./esES.json";
import viVN from "./viVN.json";
import thTH from "./thTH.json";
import trTR from "./trTR.json";
import svSE from "./svSE.json";
import ptPT from "./ptPT.json";
import idID from "./idID.json";
import noNB from "./noNB.json";
import nlNL from "./nlNL.json";
import elGR from "./elGR.json";
import fiFI from "./fiFI.json";
import jaJP from "./jaJP.json";
import deDE from "./deDE.json";
import daDK from "./daDK.json";
import itIT from "./itIT.json";
import koKR from "./koKR.json";
import plPL from "./plPL.json";
import zhHK from "./zhHK.json";
import roRO from "./roRO.json";
import etEE from "./etEE.json";
import ltLT from "./ltLT.json";
import lvLV from "./lvLV.json";
import arSA from "./arSA.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  "en-US": { translation: enUS },
  "fr-FR": { translation: frFR },
  "es-ES": { translation: esES },
  "vi-VN": { translation: viVN },
  "th-TH": { translation: thTH },
  "tr-TR": { translation: trTR },
  "sv-SE": { translation: svSE },
  "pt-PT": { translation: ptPT },
  "id-ID": { translation: idID },
  "no-NB": { translation: noNB },
  "nl-NL": { translation: nlNL },
  "el-GR": { translation: elGR },
  "fi-FI": { translation: fiFI },
  "ja-JP": { translation: jaJP },
  "de-DE": { translation: deDE },
  "da-DK": { translation: daDK },
  "it-IT": { translation: itIT },
  "ko-KR": { translation: koKR },
  "pl-PL": { translation: plPL },
  "zh-HK": { translation: zhHK },
  "ro-RO": { translation: roRO },
  "et-EE": { translation: etEE },
  "lt-LT": { translation: ltLT },
  "lv-LV": { translation: lvLV },
  "ar-SA": { translation: arSA },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en-US", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
