/* eslint-disable semi */
import Price from "./Price";

interface AncilDetails {
  column: "A" | "B" | "C" | "D" | "E" | "F" | string;
  passengerId: string;
  row: number;
  segmentId: string;
  checked: boolean;
  displayName: string;
  journeyId: string;
}

export default interface Basket {
  basketItemId: string;
  benefits: Record<string, unknown>[];
  displayName: string;
  events: Record<string, unknown>[];
  price: Price;
  passengerId: string;
  productDetails: AncilDetails;
  productId: string;
  productType: "seat" | "bag";
  quantity: number;
  status: string;
  ticketId: string;
  validity: {
    status: string;
    validFrom: string;
    validUntil: string;
  };
}

export class SelectedProductSummary {
  displayNames: Map<string, string>;
  quantity: number;
  price: number;

  constructor(displayNames: Map<string, string>) {
    this.displayNames = displayNames;
    this.quantity = 0;
    this.price = 0;
  }
}

export interface JourneyProductsSummary {
  journeyProducts: Record<"seat" | "bag", SelectedProductSummary>;
  passengerProducts: Record<
    string,
    Record<"seat" | "bag", SelectedProductSummary>
  >;
}
