import React, { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPartnerCustomization } from "../api/partnerColors";
import Colors from "../types/Colors";
import { getSessionId } from "../utils/urlUtils";

// Below is what the CustomizationContext provides to it's children
interface CustomizationContextInterface {
  colors: Colors;
  partnerUrls: PartnerUrls;
  logoUrl: string;
  tosUrl: string;
}

export const CustomizationContext =
  createContext<CustomizationContextInterface | null>(null); // Boilerplate

// Boilerplate
interface CustomizationProviderProps {
  children: JSX.Element | React.ReactNode;
}

interface PartnerUrls {
  returnUrl: string;
  supportUrl: string;
}

export const CustomizationProvider = ({
  children,
}: CustomizationProviderProps) => {
  const [colors, setColors] = useState(defaultColors);
  const [partnerUrls, setPartnerUrls] = useState({} as PartnerUrls); // Note these URLs will not load on NotFound.tsx (404s). TODO: Use open endpoint
  const [logoUrl, setLogoUrl] = useState("");
  const [tosUrl, setTosUrl] = useState("");
  const { i18n, t } = useTranslation();

  useEffect(() => {
    const sessionId = getSessionId(window.location.pathname);
    getPartnerCustomization(sessionId)
      .then((customizations) => {
        const colors: Colors = {
          primary: customizations.primaryColor || "#092A5E",
          secondary: customizations.secondaryColor || "#D40E14",
          accent1: customizations.accent1 || "#70CBF4",
          accent2: customizations.accent2 || "#C2E6FA",
          accent3: customizations.accent3 || "#E2F3FE",
          accent4: customizations.accent4 || "#23213E",
          textColor: customizations.textColor || "#092A5E",
        };
        const urls: PartnerUrls = {
          returnUrl: customizations.returnUrl,
          supportUrl: customizations.supportUrl,
        };
        setCSSColorsVariables(colors);
        setColors(colors);
        setPartnerUrls(urls);
        setLogoUrl(customizations.logo);
        setIcon(customizations.faviconPath);
        setTosUrl(customizations.tosPath);
        i18n.changeLanguage(customizations.language);
        changePageTitle(t("page-title"));
      })
      .catch((e) => {
        console.error(e);
        setCSSColorsVariables(defaultColors);
      });
  }, []);

  return (
    <CustomizationContext.Provider
      value={{
        colors,
        partnerUrls,
        logoUrl,
        tosUrl,
      }}
    >
      {children}
    </CustomizationContext.Provider>
  );
};

const defaultColors: Colors = {
  primary: "#092A5E",
  secondary: "#D40E14",
  accent1: "#70CBF4",
  accent2: "#C2E6FA",
  accent3: "#E2F3FE",
  accent4: "#23213E",
  textColor: "#092A5E",
};

// Sets the CSS variables for tailwinds
const setCSSColorsVariables = (colors: Colors) => {
  for (const [type, hex] of Object.entries(colors)) {
    const root = document.documentElement;
    root.style.setProperty(`--color-${type}`, hex);
  }
};

const setIcon = (path: string) => {
  const linkElement = document.querySelector("link[rel='icon']");
  const newLinkElement = document.createElement("link");
  newLinkElement.rel = "icon";
  newLinkElement.href = path;
  if (linkElement) {
    document.head.removeChild(linkElement);
  }
  document.head.appendChild(newLinkElement);
};

const changePageTitle = (title: string) => {
  document.querySelector("title").textContent = title;
};
