import { SelectedProductSummary } from "./Basket";
import Passenger from "./Passenger";

export enum FlightDirection {
  Depart = "depart",
  Return = "return",
}

export interface TripCardProps {
  direction: FlightDirection;
}

export interface TripCardHeaderProps {
  direction: FlightDirection;
  isCardExpanded: boolean;
  onCardTopClick: () => void;
}

export interface JourneyDirectionTagProps {
  direction?: FlightDirection;
}

export interface CardTopRowProps {
  isCardExpanded: boolean;
  onCardTopClick: () => void;
  dateTime: string;
  passengerNum: number;
  timezone: string;
}

export interface SelectionInfoItemProps extends SelectedProductSummary {
  productType: string;
  language: string;
  currency: string;
}

export interface AirlineInfoProps {
  airlineCode: string;
  flightNumber: string;
}

export interface FlightDetailsItemProps {
  airportCode: string;
  dateTime: string;
  takeoff: boolean;
  timezone: string;
}

export interface PassengerItemProps {
  passenger: Passenger;
  direction: FlightDirection;
}
