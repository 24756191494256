import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const TravelerSVG = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 166 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_617_8699)">
        <path
          d="M108.387 65.0886C109.21 65.3172 110.088 65.236 110.855 64.8604C111.622 64.4848 112.225 63.8409 112.549 63.0506C112.873 62.2604 112.896 61.3787 112.613 60.5728C112.331 59.7668 111.762 59.0925 111.016 58.6776L103.61 50.2764L97.347 51.6295L106.156 60.2581C105.94 60.7168 105.826 61.2175 105.824 61.7247C105.822 62.232 105.931 62.7336 106.144 63.1941C106.357 63.6547 106.668 64.063 107.055 64.3903C107.443 64.7176 107.897 64.956 108.387 65.0886L108.387 65.0886Z"
          fill="#FACF9D"
        />
        <path
          d="M84.0238 35.0112L102.481 58.179L108.107 55.596L86.4127 21.8218L84.0238 35.0112Z"
          fill={colors.primary}
        />
        <path
          d="M152.992 115.082C154.149 112.838 153.521 110.007 152.081 107.933C150.641 105.859 148.515 104.382 146.435 102.95L143.544 100.959L144.925 107.891C145.391 110.234 145.966 112.765 147.796 114.302C148.515 114.891 149.373 115.285 150.288 115.446C151.204 115.607 152.145 115.53 153.022 115.222"
          fill={colors.accent3}
        />
        <path
          d="M152.774 123.613C152.759 123.666 152.724 123.711 152.676 123.738C152.628 123.765 152.571 123.773 152.518 123.758C152.466 123.742 152.422 123.707 152.395 123.659C152.368 123.612 152.36 123.556 152.373 123.503C152.534 122.329 152.694 121.154 152.769 119.971C152.895 118.016 152.779 116.009 152.055 114.168C151.4 112.512 150.235 111.108 148.73 110.156C148.504 110.014 148.713 109.655 148.94 109.798C149.81 110.346 150.578 111.041 151.208 111.853C152.397 113.386 152.981 115.255 153.163 117.168C153.368 119.323 153.067 121.482 152.775 123.613L152.774 123.613Z"
          fill={colors.accent1}
        />
        <path
          d="M123.345 110.449C132.455 116.055 129.418 123.529 129.418 123.529L123.345 110.449Z"
          fill={colors.accent3}
        />
        <path
          d="M141.248 118.112C134.079 118.066 133.153 123.393 133.153 123.393L141.248 118.112Z"
          fill={colors.accent3}
        />
        <path
          d="M40.5257 123.971C41.6438 123.971 42.5501 123.064 42.5501 121.946C42.5501 120.828 41.6438 119.922 40.5257 119.922C39.4077 119.922 38.5014 120.828 38.5014 121.946C38.5014 123.064 39.4077 123.971 40.5257 123.971Z"
          fill={colors.primary}
        />
        <path
          d="M41.998 81.1828C41.998 81.1828 46.7829 66.6442 47.151 65.632C47.519 64.6198 51.3837 66.2761 52.3959 66.5522C53.4081 66.8282 53.132 67.8404 53.132 67.8404L47.9791 82.747L49.5434 83.4832L54.3282 68.4845C57.0887 65.632 55.0644 65.448 55.0644 65.448C55.0644 65.448 49.1753 63.4236 46.9669 63.2396C44.7585 63.0555 45.5867 65.724 45.5867 65.724L40.2497 79.8945L41.998 81.1828Z"
          fill={colors.primary}
        />
        <path
          d="M53.5 84.6802L53.4991 84.6793C52.8201 83.7057 51.856 82.9666 50.7395 82.5638C50.6999 82.5491 50.6539 82.5325 50.6015 82.5141C48.0664 81.628 30.4039 76.8588 30.4039 76.8588C30.4039 76.8588 26.9073 75.5706 25.343 79.0672C23.7787 82.5638 14.4851 111.549 14.4851 111.549C14.4851 111.549 32.662 121.27 37.9612 122.328C38.2884 122.407 38.6259 122.433 38.9614 122.407C39.8622 122.282 40.7487 122.07 41.6087 121.775C42.5599 121.465 43.4646 121.027 44.2984 120.475L54.6042 89.6491C54.6042 89.6491 56.0765 86.2445 53.5 84.6802Z"
          fill="#D40E14"
        />
        <path
          d="M53.5 84.6797L53.4991 84.6788C52.8202 83.7052 51.856 82.9661 50.7395 82.5634C50.6999 82.5486 50.6539 82.5321 50.6015 82.5137C51.4352 83.9749 51.7517 86.244 51.7517 86.244C51.7517 86.244 41.6299 116.333 40.7097 119.186C40.0086 121.36 38.5943 122.099 37.9612 122.327C38.2884 122.406 38.6259 122.433 38.9614 122.406C39.8622 122.282 40.7488 122.07 41.6087 121.774C41.8603 121.486 42.0831 121.174 42.274 120.842C42.274 120.842 52.5799 90.0167 53.5 88.5444C54.4202 87.0721 53.5 84.6797 53.5 84.6797Z"
          fill={colors.primary}
        />
        <path
          d="M34.3146 124.017C35.4326 124.017 36.339 123.11 36.339 121.992C36.339 120.874 35.4326 119.968 34.3146 119.968C33.1966 119.968 32.2903 120.874 32.2903 121.992C32.2903 123.11 33.1966 124.017 34.3146 124.017Z"
          fill={colors.primary}
        />
        <path
          d="M14.6691 115.506C15.7872 115.506 16.6935 114.599 16.6935 113.481C16.6935 112.363 15.7872 111.457 14.6691 111.457C13.5511 111.457 12.6448 112.363 12.6448 113.481C12.6448 114.599 13.5511 115.506 14.6691 115.506Z"
          fill={colors.primary}
        />
        <path
          d="M50.3515 66.8646C49.5373 67.1224 48.6568 67.0725 47.8768 66.7245C47.0969 66.3764 46.4717 65.7543 46.1198 64.9762C45.7678 64.198 45.7136 63.3177 45.9673 62.5022C46.221 61.6867 46.7651 60.9925 47.4964 60.5513L54.5976 51.8916L60.9052 53.0208L52.4089 61.9577C52.6416 62.4084 52.7727 62.9047 52.7929 63.4116C52.8131 63.9185 52.7219 64.4236 52.5258 64.8914C52.3297 65.3593 52.0333 65.7784 51.6577 66.1193C51.282 66.4602 50.8362 66.7147 50.3516 66.8646L50.3515 66.8646Z"
          fill="#FACF9D"
        />
        <path
          d="M98.7507 104.968L103.149 104.899L102.62 119.892H98.7514L98.7507 104.968Z"
          fill="#FACF9D"
        />
        <path
          d="M97.7645 118.629L105.384 118.629H105.384C106.672 118.629 107.907 119.141 108.818 120.051C109.729 120.962 110.24 122.197 110.24 123.485V123.642L97.7647 123.643L97.7645 118.629Z"
          fill={colors.accent4}
        />
        <path
          d="M69.6262 100.992L74.4314 104.077L64.8195 115.641L61.5637 113.55L69.6262 100.992Z"
          fill="#FACF9D"
        />
        <path
          d="M61.4157 111.954L67.8277 116.071L67.828 116.071C68.9116 116.767 69.6744 117.864 69.9487 119.123C70.223 120.381 69.9863 121.697 69.2906 122.78L69.2053 122.913L58.7071 116.173L61.4157 111.954Z"
          fill={colors.accent4}
        />
        <path
          d="M67.9143 55.2111C67.9143 55.2111 78.5909 19.7505 82.0221 19.2713C85.4533 18.7921 90.9816 28.3701 90.9816 28.3701C98.1493 37.5153 105.54 67.1389 105.54 67.1389C105.54 67.1389 93.2192 67.9099 84.2124 72.7019C75.2056 77.4938 64.5763 72.4593 64.5763 72.4593L67.9143 55.2111Z"
          fill={colors.primary}
        />
        <path
          opacity="0.1"
          d="M67.9143 55.2115C67.9143 55.2115 78.5909 19.7508 82.0221 19.2716C85.4533 18.7924 89.5775 28.4676 89.5775 28.4676C93.1491 43.2302 105.54 67.1392 105.54 67.1392C105.54 67.1392 93.2192 67.9102 84.2124 72.7022C75.2056 77.4941 64.5763 72.4596 64.5763 72.4596L67.9143 55.2115Z"
          fill={colors.primary}
        />
        <path
          d="M76.6737 44.6009V62.4197L74.5767 88.2314L64.1 107.756L69.1002 111.328L83.3867 92.7554L89.0372 74.3143L97.9112 115.137L104.578 114.661L99.9479 58.0606L91.1616 42.1289L76.6737 44.6009Z"
          fill={colors.accent4}
        />
        <path
          d="M87.4384 27.3666C90.7719 30.7001 87.6765 34.2717 87.6765 34.2717L91.4862 42.8436L76.4855 46.8914L71.2433 23.4669L74.8149 19.6571C81.9581 19.419 81.962 19.271 81.962 19.271C83.2135 20.4958 87.4384 27.3666 87.4384 27.3666Z"
          fill={colors.accent2}
        />
        <path
          d="M70.5563 21.1017C69.3162 22.0137 68.4464 23.3421 68.1063 24.8434C67.7661 26.3447 67.9785 27.9183 68.7044 29.2757C70.423 32.5563 72.2716 37.638 71.3184 43.2168C69.5935 53.3131 64.0641 60.9838 60.9841 63.4473C59.9118 64.3048 59.8743 65.5537 60.0318 66.4504C60.4715 68.9554 62.9003 71.798 64.5802 72.3679C64.6933 72.4054 64.8118 72.424 64.931 72.423C67.6601 72.424 74.3734 63.7319 82.0878 46.7526C86.8923 36.1781 83.4053 27.6994 79.6342 22.4486C79.1391 21.7616 78.5127 21.1796 77.7911 20.7364C77.0696 20.2932 76.2673 19.9975 75.4306 19.8666C74.594 19.7356 73.7397 19.7719 72.9172 19.9735C72.0948 20.175 71.3204 20.5378 70.6391 21.0407L70.5563 21.1017Z"
          fill={colors.primary}
        />
        <path
          d="M77.6721 31.3239L56.0082 59.7488L50.2936 57.3678L70.7708 22.8423L77.6721 31.3239Z"
          fill={colors.primary}
        />
        <path
          d="M84.0981 16.1847H69.1976C68.8911 16.1843 68.5973 16.0624 68.3806 15.8457C68.1638 15.629 68.0419 15.3351 68.0416 15.0286V8.60597C68.041 7.47539 68.2631 6.35577 68.6954 5.31108C69.1276 4.26639 69.7615 3.3171 70.5607 2.51745C71.3599 1.7178 72.3089 1.08346 73.3533 0.650667C74.3978 0.217875 75.5173 -0.00488281 76.6479 -0.00488281C77.7785 -0.00488281 78.898 0.217875 79.9424 0.650667C80.9869 1.08346 81.9359 1.7178 82.7351 2.51745C83.5343 3.3171 84.1682 4.26639 84.6004 5.31108C85.0326 6.35577 85.2548 7.47539 85.2542 8.60597V15.0286C85.2539 15.3351 85.132 15.629 84.9152 15.8457C84.6985 16.0624 84.4046 16.1843 84.0981 16.1847Z"
          fill={colors.accent4}
        />
        <path
          d="M83.953 12.7519C85.6245 9.69412 84.5008 5.86025 81.443 4.18871C78.3852 2.51717 74.5513 3.64095 72.8798 6.69874C71.2082 9.75652 72.332 13.5904 75.3898 15.2619C78.4476 16.9335 82.2815 15.8097 83.953 12.7519Z"
          fill="#FACF9D"
        />
        <path
          d="M87.2903 9.24845H78.179L78.0855 7.94034L77.6184 9.24845H76.2154L76.0303 6.65582L75.1044 9.24845H72.3897V9.12C72.3918 7.31504 73.1097 5.58458 74.386 4.30828C75.6623 3.03197 77.3927 2.31405 79.1977 2.31201H80.4823C82.2872 2.31405 84.0177 3.03197 85.294 4.30827C86.5703 5.58458 87.2882 7.31503 87.2903 9.12V9.24845Z"
          fill={colors.accent4}
        />
        <path
          d="M78.1044 17.3842C78.0358 17.3841 77.9673 17.378 77.8998 17.3661L71.2281 16.1889V5.1626H78.5723L78.3905 5.37459C75.8607 8.32499 77.7666 13.1091 79.1279 15.6982C79.2282 15.8878 79.2729 16.102 79.2569 16.3159C79.2409 16.5298 79.1648 16.7349 79.0373 16.9075C78.9306 17.0551 78.7904 17.1752 78.6282 17.2581C78.466 17.3409 78.2865 17.3841 78.1044 17.3842Z"
          fill={colors.accent4}
        />
      </g>
      <defs>
        <clipPath id="clip0_617_8699">
          <rect
            width="165.831"
            height="124"
            fill="white"
            transform="translate(0.168701)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
