import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const LuggageIcon = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1003_3165)">
        <path
          d="M11.3333 3.99967H9.99992V1.99967C9.99992 1.63301 9.69992 1.33301 9.33325 1.33301H6.66658C6.29992 1.33301 5.99992 1.63301 5.99992 1.99967V3.99967H4.66659C3.93325 3.99967 3.33325 4.59967 3.33325 5.33301V12.6663C3.33325 13.3997 3.93325 13.9997 4.66659 13.9997C4.66659 14.3663 4.96659 14.6663 5.33325 14.6663C5.69992 14.6663 5.99992 14.3663 5.99992 13.9997H9.99992C9.99992 14.3663 10.2999 14.6663 10.6666 14.6663C11.0333 14.6663 11.3333 14.3663 11.3333 13.9997C12.0666 13.9997 12.6666 13.3997 12.6666 12.6663V5.33301C12.6666 4.59967 12.0666 3.99967 11.3333 3.99967ZM6.33325 11.9997H5.33325V5.99967H6.33325V11.9997ZM8.49992 11.9997H7.49992V5.99967H8.49992V11.9997ZM8.99992 3.99967H6.99992V2.33301H8.99992V3.99967ZM10.6666 11.9997H9.66658V5.99967H10.6666V11.9997Z"
          fill={colors.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1003_3165">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
