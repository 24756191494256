import React, { createContext } from "react";
import { useSearchParams } from "react-router-dom";

// Below is what ErrorContext provides to it's children
interface AuthContextInterface {
  accessToken: string;
  refreshToken: string;
}

export const AuthContext = createContext<AuthContextInterface | null>(null);

interface AuthProviderProps {
  children: JSX.Element | React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get("access_token");
  const refreshToken = searchParams.get("refresh_token");

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        refreshToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
