import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const FlightLandIcon = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_702_7899)">
        <path
          d="M14.3333 12.6654H1.66668V13.9987H14.3333V12.6654ZM3.10668 10.5654C2.57335 10.7054 2.02668 10.392 1.88001 9.8587C1.74001 9.32536 2.05334 8.7787 2.58668 8.63203L6.12668 7.68536L7.96668 1.67203L9.25334 1.33203V6.85203L12.5667 5.96536L13.1867 4.4187L14.1533 4.1587V7.60536L3.10668 10.5654Z"
          fill={colors.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_702_7899">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-1 0 0 1 16 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
