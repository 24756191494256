import React from "react";

type ButtonType = "button" | "reset" | "submit";
interface ButtonProps {
  id?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonText: string;
  isSecondary?: boolean;
  margin?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  type?: ButtonType;
}

export const Button = ({
  id = "",
  onClick,
  buttonText,
  isSecondary = false,
  margin = "",
  isDisabled = false,
  isLoading = false,
  type = "button",
}: ButtonProps) => {
  const color = isSecondary
    ? "bg-partner-secondary"
    : isLoading
    ? "bg-loading-dark"
    : "bg-partner-primary";
  const opacity = isDisabled || isLoading ? "opacity-70" : "";
  const animation = isLoading ? "animate-pulse" : "";
  return (
    <button
      id={id}
      className={`w-full rounded-[10px] text-white py-4 min-h-[56px] ${margin} ${color} ${opacity} ${animation}`}
      onClick={onClick}
      disabled={isDisabled || isLoading}
      type={type}
    >
      {!isLoading ? buttonText : ""}
    </button>
  );
};
