import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const ErrorSVG = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 374 421"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1405_4568)">
        <path
          d="M161.977 323.954C72.5198 323.954 0.000213623 251.434 0.000213623 161.977C0.000213623 72.5195 72.5198 0 161.977 0C251.435 0 323.954 72.5195 323.954 161.977C323.954 251.434 251.435 323.954 161.977 323.954Z"
          fill="#F2F2F2"
        />
        <path
          d="M56.1275 72.436H352.847C353.483 72.4224 353.988 71.8955 353.974 71.2593C353.961 70.6422 353.464 70.145 352.847 70.1318H56.1275C55.4912 70.1454 54.9864 70.6723 55.0001 71.3085C55.0132 71.9256 55.5104 72.4228 56.1275 72.436Z"
          fill="#E1E1E1"
        />
        <path
          d="M157.249 111.262H239.274C245.435 111.262 250.429 116.256 250.429 122.417V122.417C250.429 128.578 245.435 133.573 239.274 133.573H157.249C151.088 133.573 146.093 128.578 146.093 122.417C146.093 116.256 151.088 111.262 157.249 111.262Z"
          fill="white"
        />
        <path
          d="M292.262 155.884H104.261C98.0995 155.884 93.1051 160.878 93.1051 167.039C93.1051 173.2 98.0995 178.195 104.261 178.195H292.262C298.423 178.195 303.418 173.2 303.418 167.039C303.418 160.878 298.423 155.884 292.262 155.884Z"
          fill="white"
        />
        <path
          d="M292.262 200.506H104.261C98.0995 200.506 93.1051 205.5 93.1051 211.661C93.1051 217.822 98.0995 222.817 104.261 222.817H292.262C298.423 222.817 303.418 217.822 303.418 211.661C303.418 205.5 298.423 200.506 292.262 200.506Z"
          fill="white"
        />
        <path
          d="M315.56 419.456L316.003 419.413C331.015 417.864 344.586 409.79 353.11 397.337C355.445 393.866 357.333 390.116 358.731 386.174L358.865 385.798L358.468 385.766C353.719 385.384 348.84 384.578 346.673 384.195L360.648 379.459L360.734 379.045C361.563 375.152 359.94 371.147 356.635 368.93C353.321 366.606 348.927 366.531 345.536 368.742C344.015 369.714 342.423 370.676 340.884 371.607C335.596 374.806 330.127 378.114 326.027 382.64C319.81 389.641 317.084 399.074 318.61 408.312L315.56 419.456Z"
          fill="#F2F2F2"
        />
        <path
          d="M264.761 412.506L257.874 412.506L254.597 385.938L264.763 385.938L264.761 412.506Z"
          fill="#A0616A"
        />
        <path
          d="M265.004 419.691L243.823 419.69V419.422C243.823 414.869 247.514 411.179 252.067 411.178H252.068L255.937 408.243L263.155 411.179L265.004 411.179L265.004 419.691Z"
          fill={colors.primary}
        />
        <path
          d="M319.96 393.429L314.451 397.562L295.885 378.277L304.017 372.177L319.96 393.429Z"
          fill="#A0616A"
        />
        <path
          d="M324.467 399.031L307.524 411.742L307.363 411.527C304.631 407.885 305.368 402.718 309.01 399.985L309.01 399.985L310.344 395.315L317.88 393.331L319.359 392.222L324.467 399.031H324.467Z"
          fill={colors.primary}
        />
        <path
          d="M297.064 287.829C297.064 287.829 296.684 298.201 296.204 306.519C296.126 307.883 299.322 309.365 299.239 310.849C299.184 311.834 298.583 312.791 298.527 313.817C298.468 314.901 299.298 315.86 299.239 316.98C299.181 318.075 298.235 319.33 298.178 320.451C297.564 332.509 295.337 346.945 297.567 350.981C298.665 352.969 312.671 380.522 312.671 380.522C312.671 380.522 304.868 392.227 302.638 388.325C299.693 383.172 276.998 359.063 276.998 354.459C276.998 349.86 271.748 315.397 271.748 315.397L268.087 335.792L266.408 339.833L266.936 342.205L265.851 345.407L265.792 348.575L264.736 354.459C264.736 354.459 267.913 400.298 266.124 401.574C264.329 402.856 255.639 405.158 254.614 402.856C253.594 400.554 246.955 355.016 246.955 355.016C246.955 355.016 245.884 334.148 244.725 314.751C244.658 313.625 243.867 312.265 243.805 311.155C243.733 309.868 244.234 308.196 244.168 306.947C244.095 305.515 243.46 304.506 243.398 303.135C242.925 292.539 245.584 279.636 246.431 278.365C248.99 274.53 251.292 271.465 251.292 271.465C251.292 271.465 285.751 260.143 294.959 271.647L297.064 287.829L297.064 287.829Z"
          fill={colors.primary}
        />
        <path
          d="M262.696 181.174L276.356 178.442L282.731 187.094C294.626 198.79 294.805 209.112 292.682 225.658V248.881L294.219 264.448C294.219 264.448 299.659 275.828 294.048 278.478C288.436 281.127 251.578 280.901 248.059 279.844C244.539 278.786 247.59 277.488 248.514 272.558C250.25 263.299 249.089 267.766 248.969 264.817C247.256 222.529 251.246 205.168 251.651 200.605L258.598 188.005L262.696 181.174Z"
          fill={colors.accent4}
        />
        <path
          d="M190.699 215.271C192.122 217.969 195.463 219.001 198.161 217.578C198.448 217.426 198.722 217.249 198.979 217.049L228.479 235.78L228.394 225.582L199.737 208.951C197.695 206.663 194.185 206.462 191.897 208.504C189.982 210.212 189.486 213.009 190.699 215.271Z"
          fill="#A0616A"
        />
        <path
          d="M273.482 192.984L276.841 192.629C279.944 192.307 282.989 193.636 284.863 196.13C285.58 197.076 286.096 198.16 286.377 199.313L286.379 199.323C287.222 202.795 285.9 206.434 283.026 208.557L271.426 217.11C263.285 228.109 247.986 235.407 230.04 241.336L202.145 224.012L212.012 212.062L231.145 222.316L259.585 200.3L259.602 200.287L270.426 193.313L273.482 192.984L273.482 192.984Z"
          fill={colors.accent4}
        />
        <path
          d="M267.312 174.467C258.514 174.467 251.382 167.335 251.382 158.537C251.382 149.739 258.514 142.607 267.312 142.607C276.11 142.607 283.242 149.739 283.242 158.537C283.242 167.335 276.11 174.467 267.312 174.467Z"
          fill="#A0616A"
        />
        <path
          d="M266.944 174.47C267.073 174.474 267.201 174.477 267.33 174.48C267.385 174.623 267.444 174.765 267.513 174.903L266.944 174.47V174.47Z"
          fill={colors.primary}
        />
        <path
          d="M262.572 158.979C262.547 159.135 262.509 159.29 262.458 159.441C262.479 159.283 262.517 159.128 262.572 158.979Z"
          fill={colors.primary}
        />
        <path
          d="M251.179 143.289C251.835 145.325 252.42 142.742 254.577 143.207C257.172 143.766 260.191 143.574 262.316 141.983C265.482 139.66 269.585 139.035 273.299 140.309C276.935 141.595 283.027 142.502 283.974 146.241C284.303 147.54 284.434 148.951 285.232 150.028C285.93 150.969 287.032 151.506 287.953 152.231C291.063 154.678 288.686 161.631 287.214 165.304C285.742 168.978 282.365 171.654 278.652 173.025C275.06 174.351 271.162 174.585 267.33 174.481C266.663 172.752 266.951 170.756 267.501 168.964C268.096 167.024 268.978 165.152 269.227 163.138C269.475 161.124 268.929 158.836 267.241 157.711C265.689 156.677 263.163 157.307 262.572 158.979C262.917 156.888 260.799 154.865 258.615 154.509C256.271 154.127 253.926 154.974 251.588 155.395C249.251 155.816 250.08 145.974 251.179 143.289Z"
          fill={colors.primary}
        />
        <path
          d="M162.088 184.887C160.603 166.944 173.945 151.195 191.888 149.71C209.831 148.225 225.58 161.567 227.065 179.51C228.55 197.452 215.208 213.202 197.265 214.687C179.323 216.172 163.573 202.83 162.088 184.887Z"
          fill={colors.secondary}
        />
        <path
          d="M186.013 168.616L194.576 177.179L203.14 168.616C204.526 167.23 206.773 167.23 208.16 168.616C209.546 170.002 209.546 172.249 208.16 173.636L199.596 182.199L208.16 190.762C209.544 192.15 209.542 194.397 208.155 195.782C206.769 197.165 204.525 197.165 203.14 195.782L194.576 187.219L186.013 195.782C184.626 197.167 182.378 197.165 180.993 195.777C179.61 194.392 179.61 192.148 180.993 190.762L189.557 182.199L180.993 173.636C179.607 172.249 179.607 170.002 180.993 168.616C182.379 167.23 184.627 167.23 186.013 168.616Z"
          fill="white"
        />
        <path
          d="M207.423 154.799C206.462 157.693 208.03 160.819 210.925 161.78C211.233 161.883 211.55 161.957 211.872 162.004L218.779 196.26L226.079 189.138L218.24 156.945C218.478 153.888 216.193 151.216 213.135 150.978C210.577 150.778 208.216 152.358 207.423 154.799Z"
          fill="#A0616A"
        />
        <path
          d="M280.815 199.107L283.397 201.284C285.778 203.301 286.927 206.418 286.424 209.497C286.238 210.67 285.813 211.792 285.176 212.794L285.17 212.802C283.248 215.814 279.707 217.379 276.185 216.775L261.983 214.325C248.41 216.066 232.552 210.079 215.85 201.233L209.039 169.11L224.493 167.957L230.339 188.863L265.916 194.144L265.937 194.147L278.463 197.129L280.815 199.107L280.815 199.107Z"
          fill={colors.accent4}
        />
        <path
          d="M199.947 420.241L373.243 420.437C373.662 420.436 374.001 420.095 374 419.675C373.999 419.258 373.661 418.92 373.243 418.919L199.947 418.723C199.528 418.724 199.189 419.065 199.19 419.484C199.191 419.902 199.53 420.24 199.947 420.241Z"
          fill="#E1E1E1"
        />
        <path
          d="M337.793 57.8424C334.815 57.8424 332.4 55.3742 332.4 52.3294C332.4 49.2847 334.815 46.8164 337.793 46.8164C340.772 46.8164 343.186 49.2847 343.186 52.3294C343.186 55.3742 340.772 57.8424 337.793 57.8424Z"
          fill={colors.accent4}
        />
        <path
          d="M319.163 57.8424C316.185 57.8424 313.771 55.3742 313.771 52.3294C313.771 49.2847 316.185 46.8164 319.163 46.8164C322.142 46.8164 324.556 49.2847 324.556 52.3294C324.556 55.3742 322.142 57.8424 319.163 57.8424Z"
          fill={colors.accent4}
        />
        <path
          d="M300.534 57.8424C297.556 57.8424 295.141 55.3742 295.141 52.3294C295.141 49.2847 297.556 46.8164 300.534 46.8164C303.512 46.8164 305.927 49.2847 305.927 52.3294C305.927 55.3742 303.512 57.8424 300.534 57.8424Z"
          fill={colors.accent4}
        />
        <path
          d="M279.291 56.6869C279.456 56.6869 279.622 56.6228 279.747 56.4951L283.385 52.7754C283.628 52.5275 283.628 52.1314 283.385 51.8836L279.747 48.1642C279.5 47.9126 279.096 47.9085 278.845 48.1542C278.593 48.4005 278.589 48.8043 278.835 49.0559L282.038 52.3295L278.835 55.6033C278.589 55.8549 278.593 56.2588 278.845 56.5051C278.969 56.6265 279.13 56.6869 279.291 56.6869Z"
          fill={colors.accent4}
        />
        <path
          d="M273.176 56.6864C273.337 56.6864 273.498 56.626 273.622 56.5046C273.874 56.2583 273.878 55.8547 273.632 55.6028L270.43 52.329L273.632 49.0554C273.878 48.8038 273.874 48.4 273.622 48.1537C273.371 47.9071 272.967 47.9117 272.72 48.1636L269.082 51.8831C268.839 52.1309 268.839 52.527 269.082 52.7749L272.72 56.4946C272.845 56.6223 273.011 56.6864 273.176 56.6864Z"
          fill={colors.accent4}
        />
        <path
          d="M82.8154 47.4551H89.5815C90.3531 47.4551 90.978 48.08 90.978 48.8516V55.6241C90.978 56.3957 90.3531 57.0206 89.5815 57.0206H82.8154C82.0438 57.0206 81.4125 56.3957 81.4125 55.6241V48.8516C81.4125 48.08 82.0438 47.4551 82.8154 47.4551Z"
          fill={colors.accent4}
        />
        <path
          d="M99.3955 47.4551H106.162C106.933 47.4551 107.558 48.08 107.558 48.8516V55.6241C107.558 56.3957 106.933 57.0206 106.162 57.0206H99.3955C98.6239 57.0206 97.9925 56.3957 97.9925 55.6241V48.8516C97.9925 48.08 98.6239 47.4551 99.3955 47.4551Z"
          fill={colors.accent4}
        />
        <path
          d="M67.1914 47.7734H73.9574C74.7291 47.7734 75.354 48.3984 75.354 49.17V55.9424C75.354 56.7141 74.7291 57.339 73.9574 57.339H67.1914C66.4198 57.339 65.7884 56.7141 65.7884 55.9424V49.17C65.7884 48.3984 66.4198 47.7734 67.1914 47.7734Z"
          fill={colors.accent4}
        />
        <path
          d="M169.175 50.3496H223.258C224.202 50.3496 224.961 51.1149 224.961 52.0523C224.961 52.9898 224.202 53.755 223.258 53.755H169.175C168.237 53.755 167.472 52.9897 167.472 52.0523C167.472 51.1149 168.237 50.3496 169.175 50.3496Z"
          fill={colors.accent4}
        />
      </g>
      <defs>
        <clipPath id="clip0_1405_4568">
          <rect
            width="374"
            height="420.437"
            fill="white"
            transform="matrix(-1 0 0 1 374 0)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
