import React, { useContext, useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { PostBookingContext } from "../../context/PostBookingContext";
import { useTranslation } from "react-i18next";
import { ErrorContext } from "../../context/ErrorContext";
import { showUpsell } from "../../utils/gordianUtils";
import {
  sendSeatMapLoadedEvent,
  sendSeatMapErrorEvent,
} from "../../utils/fullstory_events/events";

interface GordianWidgetProps {
  upsellType: string[];
  sessionId: string;
}

export default function GordianWidget({
  upsellType,
  sessionId,
}: GordianWidgetProps) {
  const { toggleOpenUpsell } = useContext(PostBookingContext);
  const { errorType } = useContext(ErrorContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  // Observer for widget
  const widgetObserver = new MutationObserver((_mutations, obs) => {
    const upsellDialog = document.getElementById("gordian-seatmap-dialog"); // TODO: Test this with bag widget
    if (upsellDialog) {
      upsellDialog.classList.add("min-h-[94vh]", "block", "relative", "h-full");
      upsellDialog.classList.remove("gordian-embedded");
      setLoading(false);
      sendSeatMapLoadedEvent(sessionId, {});
      obs.disconnect();
    }
  });

  useEffect(() => {
    widgetObserver.observe(document, {
      childList: true,
      subtree: true,
    });
  }, []);

  // Call Gordian.showUpsell on initialization or when setUpsellType called
  useEffect(() => {
    const upsell = document.getElementById("upsell-container");
    if (window.sdkInitialized && upsell) {
      setLoading(true);
      showUpsell(upsellType);
    }
  }, [window.sdkInitialized, upsellType]);

  // Show error messages if onSeatFailed or onBagFailed SDK callbacks fired
  useEffect(() => {
    if (errorType === "seatLoadFailed") {
      sendSeatMapErrorEvent(sessionId, {});
      setLoading(false);
    } else if (errorType === "bagLoadFailed") {
      setLoading(false);
    }
  }, [errorType]);

  return (
    <div
      id="slideover-container"
      className="w-full h-full fixed inset-0 invisible"
    >
      <div
        id="slideover-bg"
        onClick={toggleOpenUpsell}
        className="w-full h-full duration-500 ease-out transition-all inset-0 absolute bg-gray-900 opacity-0"
      ></div>
      <div
        id="slideover"
        className="w-screen max-w-6xl bg-white h-full absolute right-0 duration-700 ease-out transition-all translate-x-full z-10"
      >
        <div className="flex h-full flex-col pt-6 shadow-xl">
          <div className="px-5 pb-2">
            <div className="flex items-start justify-between">
              <h1 className="text-lg font-medium text-gray-900">
                {upsellType.includes("seats")
                  ? t("choose-seats")
                  : t("choose-bags")}
              </h1>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  onClick={toggleOpenUpsell}
                >
                  <span className="sr-only">Close panel</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
          {loading && <SeatMapLoader />}
          {errorType && <WidgetErrorText />}
          <div id="upsell-container" />
        </div>
      </div>
    </div>
  );
}

// TODO: Change body size to be calc(100% - 100px) where 100px is the header_height + footer_height
const SeatMapLoader = () => {
  return (
    <div className="animate-pulse flex flex-col flex-nowrap h-full w-full">
      <div className="flex flex-row flex-shrink-0">
        <div className="h-20 w-1/2 outline outline-loading-dark flex flex-col justify-center items-center">
          <div className="h-2 w-1/2 rounded-sm bg-loading-dark"></div>
        </div>
        <div className="h-20 w-1/2 outline outline-loading-dark flex flex-col justify-center items-center">
          <div className="h-2 w-1/2 rounded-sm bg-loading-dark"></div>
        </div>
      </div>
      <div className="overflow-auto flex-grow mt-6 space-y-3 flex flex-col items-center px-6">
        {[...Array(25)].map((e, row) => (
          <div
            key={`row-${row}`}
            className="flex flex-row justify-between w-full max-w-xs"
          >
            {[...Array(2)].map((e, section) => (
              <div
                key={`row-${row}, section-${section}`}
                className={"flex flex-row space-x-1 md:space-x-2"}
              >
                {[...Array(3)].map((e, seat) => (
                  <div
                    key={`row-${row}, section-${section}, seat-${seat}`}
                    className="h-10 w-10 rounded bg-loading-dark"
                  ></div>
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="flex-shrink-0 h-28 flex flex-row outline outline-loading-dark justify-between items-center px-4">
        <div className="flex flex-row space-x-2 justify-center items-center">
          <div className="rounded-full bg-loading-dark h-10 w-10 mb-8"></div>
          <div className="rounded-sm h-2 w-20 bg-loading-dark mb-8"></div>
        </div>
        <div className="h-10 w-2/5 max-w-[180px] rounded-[10px] bg-loading-dark mb-8"></div>
      </div>
    </div>
  );
};

const WidgetErrorText = () => {
  const { t } = useTranslation();
  const { errorType } = useContext(ErrorContext);

  return (
    <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:pb-24 sm:pt-10 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-indigo-600 sm:text-5xl">
            {t("uh-oh")}
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl">
                {errorType === "seatLoadFailed"
                  ? t("seat-load-failed")
                  : errorType === "bagLoadFailed"
                  ? t("bag-load-failed")
                  : ""}
              </h1>
              <p className="mt-1 text-base text-gray-500">{t("try-again")}</p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
