import _ from "lodash";

const toCamelCase = (object: unknown): Record<string, unknown> => {
  let camelCaseObject = _.cloneDeep(object);

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, toCamelCase);
  }
  camelCaseObject = _.mapKeys(camelCaseObject, (value, key) =>
    _.camelCase(key)
  );

  // Recursively apply throughout object
  return _.mapValues(camelCaseObject, (value) => {
    if (_.isPlainObject(value)) {
      return toCamelCase(value);
    }
    if (_.isArray(value)) {
      return _.map(value, toCamelCase);
    }
    return value;
  });
};

export default toCamelCase;
