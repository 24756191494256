import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Divider from "../components/Common/Divider";
import { Header } from "../components/Common/Header";
import { BasketContext } from "../context/BasketContext";
import { OrderSummary } from "../components/Specialized/OrderSummary";
import { Payments } from "../components/Payments/Payments";
import { FlightDirection } from "../types/TripCardTypes";
import { TripCard } from "../components/TripCard/TripCard";
import { useTranslation } from "react-i18next";
import { sendCheckPageLoadedEvent } from "../utils/fullstory_events/events";
import { getPostBookingSession } from "../api/postBookingSessionCall";
import { AuthContext } from "../context/AuthContext";

export const Checkout = () => {
  const { t } = useTranslation();
  const { sessionId } = useParams();
  const { initializeBasketSummary } = useContext(BasketContext);
  const [loadingBasketValidation, setLoadingBasketValidation] = useState(false);
  const navigate = useNavigate();
  const auth = useContext(AuthContext);

  useEffect(() => {
    async function initialiseTripForCheckout(sessionId) {
      const response = await getPostBookingSession(
        sessionId,
        auth.accessToken,
        auth.refreshToken
      );
      const { basket, orders, journeys, passengers } = response.trip;
      const summary = Object.keys(orders).length > 0 ? orders : basket;
      initializeBasketSummary(summary, journeys, passengers);
      sendCheckPageLoadedEvent(sessionId, {});
      const basketValidity = Object.values(basket).every((basketItem) => {
        return basketItem.validity.status === "valid";
      });
      if (basketValidity) {
        setLoadingBasketValidation(false);
      }
    }
    setLoadingBasketValidation(true);
    initialiseTripForCheckout(sessionId);
    setTimeout(() => {
      navigate("/ancillaries/" + sessionId, { replace: true });
    }, 5 * 60 * 1000);
  }, []);

  return (
    <div className="flex flex-col justify-between min-h-[90vh]">
      <div>
        <Header />
        <div className="mt-10 text-xl sm:text-xl md:text-[2.5vw] xl:text-3xl text-partner-textColor">
          {t("checkout")}
        </div>
        <div className="mt-4 mb-6 bg-partner-primary">
          <Divider />
        </div>

        {loadingBasketValidation ? (
          <SLoader />
        ) : (
          <div className="md:flex md:flex-row-reverse md:gap-8 lg:gap-12">
            <div className="w-full">
              <OrderSummary title={t("subtotal")} />
              <Payments sessionId={sessionId} />
            </div>
            <div className="w-full flex flex-col gap-6">
              <TripCard direction={FlightDirection.Depart} />
              <TripCard direction={FlightDirection.Return} />
            </div>
          </div>
        )}
      </div>
      <br />
      <Footer />
    </div>
  );
};

import ScaleLoader from "react-spinners/ScaleLoader";
import { Footer } from "../components/Common/Footer";

const SLoader = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 animate-pulse">
      <div className="flex flex-row justify-center items-center">
        <div className="mt-8 text-md sm:text-md md:text-md xl:text-md text-partner-textColor">
          {t("this-may-take-a-moment")}
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="flex flex-row justify-center items-center">
        <ScaleLoader color="#7d161a" />
      </div>
      <br></br>
      <br></br>
      <div className={"bg-loading-dark"}>
        <Divider />
      </div>
    </div>
  );
};
