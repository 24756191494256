import React from "react";

interface FormattedDateTimeProps {
  dateTime: string;
  language: string;
  timezone: string;
}

export const FormattedDateTime = ({
  dateTime,
  language,
  timezone,
}: FormattedDateTimeProps) => {
  // Extract localized date string and split on comma
  const departureDateSplit = new Date(dateTime)
    .toLocaleDateString(language, {
      timeZone: timezone,
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    })
    .split(",");
  const date = `${departureDateSplit[0]}, ${departureDateSplit[1] || ""} ${
    departureDateSplit[2] || ""
  }`;
  const is12HourFormat = language == "en-US" ? true : false;
  if (timezone == null) {
    timezone = "UTC";
  }
  const time = new Date(dateTime).toLocaleTimeString(language, {
    timeZone: timezone,
    timeZoneName: "short",
    hour12: is12HourFormat,
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <p className="date-time-text text-xs text-neutral-550 flex justify-between min-w-[144px]">
      {date} &#8226; {time}
    </p>
  );
};
