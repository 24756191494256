import React, { useEffect } from "react";
import { Header } from "../components/Common/Header";
import { useTranslation } from "react-i18next";
import { HelpButtons } from "../components/Specialized/HelpButtons";
import { ErrorSVG } from "../assets/dynamic/ErrorSVG";
import { getSessionId } from "../utils/urlUtils";
import { useNavigate } from "react-router-dom";

export const ErrorPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    const sessionId = getSessionId(location.pathname);
    if (sessionId !== undefined) {
      navigate(`/ancillaries/${sessionId}`, { replace: true });
    }
  }, []);

  return (
    <>
      <Header />
      <div className="text-partner-textColorAccent mt-16 md:mt-28 grid md:grid-cols-2 md:gap-6 lg:gap-14 xl:gap-28">
        <div>
          <p className="text-5xl mb-6">{t("oops")}</p>
          <p className="text-xl mb-6 md:mb-0">{t("generic-error-message")}</p>
          <div className="mt-8 hidden md:block">
            <HelpButtons />
          </div>
        </div>
        <div className="md:w-[30vw] max-w-[400px] md:min-w-[300px]">
          <ErrorSVG />
        </div>
        <div className="mt-8 md:hidden">
          <HelpButtons />
        </div>
      </div>
    </>
  );
};
