import { Order } from "@stripe/stripe-js";
import { ErrorType } from "../context/ErrorContext";
import Basket from "../types/Basket";
import Colors from "../types/Colors";
import { log } from "../utils/logger";

export const loadSDK = (callback: VoidFunction) => {
  const existingScript = document.getElementById("gordianSDK");
  if (!existingScript) {
    log("setting up gordian script");
    const script = document.createElement("script");
    script.src =
      "https://sdk.gordiansoftware.com/javascript/v2.2/gordian.min.js";
    script.id = "gordianSDK";
    document.body.appendChild(script);
    script.onload = callback;
  }
};

export const initSDK = async (
  tripId: string,
  tripAccessToken: string,
  onBasketChange: unknown,
  setError: (errorType: ErrorType) => void,
  updateBasketSummary: (
    basket: Record<string, Basket> | Record<string, Order>
  ) => void
) => {
  window.Gordian.init({
    tripId: tripId,
    tripAccessToken: tripAccessToken,
    searchParams: {
      is_whitelabel: true,
    },
    onBasketChange,
    eventCallbacks: {
      onSeatLoad: () => {
        log("loaded seats");
      },
      onSeatModalClosed: () => {
        log("closing seats");
      },
      onBagLoad: () => {
        log("loaded bags");
      },
      onBagModalClosed: () => {
        log("closing bags");
      },
      onSeatFail: () => {
        log("Seats failed");
        setError("seatLoadFailed");
      },
      onBagFail: () => {
        setError("bagLoadFailed");
      },
      onInvalidBasket: ({ basket }) => {
        log("invalid basket");
        updateBasketSummary(basket);
      },
    },
  });
};

export const showUpsell = (upsellType: string[]) => {
  const upsell = document.getElementById("upsell-container");
  const colors: Colors = {
    primary: "#092A5E",
    secondary: "#D40E14",
    accent1: "#70CBF4",
    accent2: "#C2E6FA",
    accent3: "#E2F3FE",
    accent4: "#23213E",
    textColor: "#092A5E",
  };
  window.Gordian.showUpsell({
    container: upsell,
    display: "embedded",
    allowProducts: upsellType,
    theme: {
      primary: colors.primary,
      secondary: colors.secondary,
      textPrimary: colors.textColor,
      textSecondary: colors.accent4,
    },
  });
};
