import React, { useContext } from "react";
import { BasketContext } from "../../context/BasketContext";
import { PostBookingContext } from "../../context/PostBookingContext";
import { PassengerItemProps, TripCardProps } from "../../types/TripCardTypes";
import Divider from "../Common/Divider";
import { SelectionInfo } from "./SelectionInfo";

export const TripCardPassengerList = ({ direction }: TripCardProps) => {
  const { trip } = useContext(PostBookingContext); // Get PostBookingContext

  return (
    <div className="mt-6">
      {trip.passengers.map((passenger) => (
        <PassengerItem
          key={direction + "-" + passenger.passengerId}
          passenger={passenger}
          direction={direction}
        />
      ))}
    </div>
  );
};

const PassengerItem = ({ passenger, direction }: PassengerItemProps) => {
  const { getSelectedPassengerProductsSummary, getCurrency } =
    useContext(BasketContext); // Get PostBookingContext
  return (
    <div>
      <div className="bg-neutral-300">
        <Divider />
      </div>
      <div className="p-4">
        <p className="pb-1 text-partner-textColorAccent">{`${passenger.firstNames} ${passenger.surname}`}</p>
        <SelectionInfo
          products={getSelectedPassengerProductsSummary(
            direction,
            passenger.passengerId
          )}
          currency={getCurrency()}
        />
      </div>
    </div>
  );
};
