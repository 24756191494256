import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { BasketContext } from "../../context/BasketContext";
import { PostBookingContext } from "../../context/PostBookingContext";
import {
  OrderSummaryItemProps,
  OrderSummaryProps,
} from "../../types/OrderSummaryTypes";
import { FlightDirection } from "../../types/TripCardTypes";

export const OrderSummary = ({ title, date }: OrderSummaryProps) => {
  const { getSelectedJourneyProductsSummary, getCurrency, getTotalCost } =
    useContext(BasketContext);
  const { getJourneyCityInfo, trip } = useContext(PostBookingContext);

  return (
    <>
      <div className="flex flex-row justify-between mb-6">
        <p className="text-neutral-550">{title}</p>
        <p className="text-partner-textColor">{date}</p>
      </div>
      <div className="flex flex-col gap-4">
        {trip?.journeys.map((journey, index) => {
          const direction =
            index === 0 ? FlightDirection.Depart : FlightDirection.Return;
          const journeyCityInfo = getJourneyCityInfo(journey);
          const productSummary = getSelectedJourneyProductsSummary(direction);
          return (
            <div key={journey.journeyId} className="flex flex-col gap-2">
              <p className="text-partner-textColor font-medium">{`${journeyCityInfo.startAirport} --> ${journeyCityInfo.endAirport}`}</p>
              <OrderSummaryItem
                itemName={"seat"}
                price={productSummary.seat.price}
                currency={getCurrency()}
                language={trip.language}
                quantity={productSummary.seat.quantity}
              />
              <OrderSummaryItem
                itemName={"bag"}
                price={productSummary.bag.price}
                currency={getCurrency()}
                language={trip.language}
                quantity={productSummary.bag.quantity}
              />
            </div>
          );
        })}
        <OrderSummaryItem
          itemName={"total"}
          price={getTotalCost()}
          currency={getCurrency()}
          language={trip?.language}
        />
      </div>
    </>
  );
};

const OrderSummaryItem = ({
  itemName,
  currency,
  price,
  language = "en-US",
  quantity,
}: OrderSummaryItemProps) => {
  const { t } = useTranslation();

  const formattedCost = new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
  }).format(price);

  return (
    (quantity === undefined || quantity > 0) && (
      <div
        className={
          quantity
            ? "text-neutral-550 font-light"
            : "text-partner-textColor font-medium"
        }
      >
        <div className={`flex flex-row justify-between`}>
          <p>
            {quantity > 1
              ? t(`${itemName}s`, { quantity })
              : quantity === 1
              ? t(`single-${itemName}`)
              : t("total")}
          </p>
          <p>{formattedCost}</p>
        </div>
      </div>
    )
  );
};
