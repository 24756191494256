import React, { useContext, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Button } from "../Common/Button";
import { EmailField } from "./EmailField";
import { useTranslation } from "react-i18next";
import { BasketContext } from "../../context/BasketContext";
import { createSearchParams, useParams } from "react-router-dom";
import Basket from "../../types/Basket";
import {
  sendStripePaymentButtonClickedEvent,
  sendStripePaymentErroredEvent,
} from "../../utils/fullstory_events/events";
import { AuthContext } from "../../context/AuthContext";
import * as EmailValidator from "email-validator";
import Swal from "sweetalert2";
import { CustomizationContext } from "../../context/CustomizationContext";
import { updatePostBookingSessionEmail } from "../../api/postBookingSessionCall";

export const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const auth = useContext(AuthContext);
  const { colors } = useContext(CustomizationContext);
  const { t } = useTranslation();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const { updateBasketSummary } = useContext(BasketContext);
  const { sessionId } = useParams();

  const handleSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const email = (
      document.getElementById("Field-emailInput") as HTMLInputElement
    ).value;
    if (!EmailValidator.validate(email)) {
      Swal.fire({
        text: t("please-enter-a-valid-email-address"),
        icon: "error",
        confirmButtonColor: colors.primary,
      });
      return;
    }
    updatePostBookingSessionEmail(email, auth.accessToken, auth.refreshToken);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    sendStripePaymentButtonClickedEvent(sessionId, {});
    const validity = window.Gordian.validateBasket(); // Ensure no items have been removed from basket (basket is valid)
    const needsCheck = basketNeedsCheck(validity.basket);
    if (!validity.isValid) {
      updateBasketSummary(validity.basket);
      // TODO: Add translation
      setMessage(
        "An item that was selected is no longer available. Please go back and ensure all desired items are present"
      );
      return;
    } else if (needsCheck) {
      setMessage(
        "We are checking if your selection is still available. Please try again shortly"
      );
      return;
    }
    setIsProcessing(true);
    setMessage("");

    const queries = createSearchParams({
      access_token: auth.accessToken,
      refresh_token: auth.refreshToken,
    });
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${
          window.location.origin
        }/confirmation/${sessionId}?${queries.toString()}`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
      sendStripePaymentErroredEvent(sessionId, {
        type: error.type,
        message: error.message,
      });
    } else {
      setMessage("An unexpected error occured."); // TODO: Add translation
      // TODO: Add error logging here
    }

    setIsProcessing(false);
  };

  return (
    <form>
      <PaymentElement />
      <EmailField />
      <Button
        onClick={handleSubmit}
        buttonText={isProcessing ? t("processing") : t("pay")}
        isDisabled={isProcessing}
        margin="mt-5"
      />
      {/* Show any error or success messages */}
      {message && (
        <div id="payment-message" className="mt-3 text-red-600">
          {message}
        </div>
      )}
    </form>
  );
};

const basketNeedsCheck = (basket: Record<string, Basket>) => {
  for (const item in basket) {
    if (basket[item].validity.status === "needs_check") {
      return true;
    }
  }
  return false;
};
