import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { authorize } from "../api/authorize";
import { Button } from "../components/Common/Button";
import { Header } from "../components/Common/Header";
import { joinCss } from "../utils/cssUtils";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getPaymentStatus } from "../api/payments";

interface BookingLoginForm {
  pnr: string;
  lastname: string;
}

const BookingLogin = () => {
  const { register, handleSubmit } = useForm<BookingLoginForm>();

  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get("access_token");

  const navigate = useNavigate();
  const { sessionId } = useParams();
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  const onSubmit = ({ pnr, lastname }: BookingLoginForm) => {
    authorize(sessionId, pnr, lastname).then((val) => {
      if (val.status === "not_authenticated") {
        setIsError(val.status === "not_authenticated");
        return;
      }
      const queries = createSearchParams({
        access_token: val.accessToken,
        refresh_token: val.refreshToken,
      });

      getPaymentStatus(val.accessToken, val.refreshToken).then((val) => {
        if (!val.allowCheckout) {
          navigate(`/confirmation/${sessionId}?${queries.toString()}`, {
            replace: true,
          });
          return;
        }
        navigate(`/ancillaries/${sessionId}/booking/?${queries.toString()}`, {
          replace: true,
        });
      });
    });
  };

  if (accessToken) {
    const queries = createSearchParams({
      access_token: accessToken,
    });
    navigate(`/ancillaries/${sessionId}/booking/?${queries.toString()}`, {
      replace: true,
    });
    return;
  }

  return (
    <div>
      <Header />
      <div className="text-partner-textColorAccent mt-16 md:mt-28 grid md:grid-cols-2 md:gap-6 lg:gap-14 xl:gap-28">
        <div className="flex flex-col md:gap-4 max-w-lg">
          <h1 className="self-end text-partner-textColor font-medium sm:w-full sm:text-xl md:text-[2.5vw] xl:text-3xl mb-5 md:mb-0 md:mt-5">
            {t("manage-your-booking")}
          </h1>
          <p>{t("manage-booking-instructions")}</p>
        </div>
        <form
          className="flex flex-col gap-4 max-w-lg mt-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h1>{t("last-name")}</h1>
          <input
            {...register("lastname")}
            className={joinCss(
              "p-4 bg-stripe-color rounded-[10px] placeholder-stripe-textColor outline-none focus:border-partner-primary",
              isError
                ? "border border-red-700"
                : "border-none focus:border-partner-primary"
            )}
          />
          <h1> {t("booking-ref-pnr")}</h1>
          <input
            {...register("pnr")}
            className={joinCss(
              "p-4 bg-stripe-color rounded-[10px] placeholder-stripe-textColor outline-none focus:border-partner-primary",
              isError
                ? "border border-red-700"
                : "border-none focus:border-partner-primary"
            )}
          />
          {isError ? (
            <p className="text-red-900">
              {t("please-input-pnr-and-lastname-to-access-booking")}
            </p>
          ) : null}
          <Button buttonText={t("submit")} type="submit" />
        </form>
      </div>
    </div>
  );
};

export default BookingLogin;
