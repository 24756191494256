import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const ConfirmationWaitingSVG = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 157 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_766_11142)">
        <path
          d="M77.7145 75.0858H30.4009C29.1522 75.0844 27.955 74.5858 27.072 73.6993C26.189 72.8127 25.6923 71.6108 25.6909 70.3571V45.7102C25.6923 44.4565 26.189 43.2545 27.072 42.368C27.955 41.4815 29.1522 40.9829 30.4009 40.9814H77.7145C78.9632 40.9829 80.1604 41.4815 81.0434 42.368C81.9264 43.2545 82.423 44.4565 82.4245 45.7102V70.3571C82.423 71.6108 81.9264 72.8127 81.0434 73.6993C80.1604 74.5858 78.9632 75.0844 77.7145 75.0858Z"
          fill="#F2F2F2"
        />
        <path
          d="M140.016 75.0858H92.7022C91.4535 75.0844 90.2563 74.5858 89.3733 73.6993C88.4903 72.8127 87.9936 71.6108 87.9922 70.3571V45.7102C87.9936 44.4565 88.4903 43.2545 89.3733 42.368C90.2563 41.4815 91.4535 40.9829 92.7022 40.9814H140.016C141.264 40.9829 142.462 41.4815 143.345 42.368C144.228 43.2545 144.724 44.4565 144.726 45.7102V70.3571C144.724 71.6108 144.228 72.8127 143.345 73.6993C142.462 74.5858 141.264 75.0844 140.016 75.0858Z"
          fill="#F2F2F2"
        />
        <path
          d="M82.4245 116.07H25.6909V84.6877C25.6923 83.434 26.189 82.2321 27.072 81.3456C27.955 80.4591 29.1522 79.9604 30.4009 79.959H77.7145C78.9632 79.9604 80.1604 80.4591 81.0434 81.3456C81.9264 82.2321 82.423 83.434 82.4245 84.6877V116.07Z"
          fill="#F2F2F2"
        />
        <path
          d="M144.726 116.07H87.9922V84.6877C87.9936 83.434 88.4903 82.2321 89.3733 81.3456C90.2563 80.4591 91.4535 79.9604 92.7022 79.959H140.016C141.264 79.9604 142.462 80.4591 143.345 81.3456C144.228 82.2321 144.724 83.434 144.726 84.6877V116.07Z"
          fill="#F2F2F2"
        />
        <path
          d="M140.015 36.1105H92.7019C91.4532 36.1091 90.256 35.6105 89.373 34.724C88.49 33.8374 87.9934 32.6355 87.9919 31.3818V4.72876C87.9934 3.47505 88.49 2.27309 89.373 1.38659C90.256 0.500078 91.4532 0.00141695 92.7019 0H140.015C141.264 0.00141695 142.461 0.500078 143.344 1.38659C144.227 2.27309 144.724 3.47505 144.725 4.72876V31.3818C144.724 32.6355 144.227 33.8374 143.344 34.724C142.461 35.6105 141.264 36.1091 140.015 36.1105Z"
          fill="#F2F2F2"
        />
        <path
          d="M77.7145 36.1105H30.4009C29.1522 36.1091 27.955 35.6105 27.072 34.724C26.189 33.8374 25.6923 32.6355 25.6909 31.3818V4.72876C25.6923 3.47505 26.189 2.27309 27.072 1.38659C27.955 0.500078 29.1522 0.00141695 30.4009 0H77.7145C78.9632 0.00141695 80.1604 0.500078 81.0434 1.38659C81.9264 2.27309 82.423 3.47505 82.4245 4.72876V31.3818C82.423 32.6355 81.9264 33.8374 81.0434 34.724C80.1604 35.6105 78.9632 36.1091 77.7145 36.1105Z"
          fill="#F2F2F2"
        />
        <path
          d="M143.155 117.503H28.4027C27.6835 117.503 26.9938 117.216 26.4852 116.705C25.9766 116.195 25.6909 115.502 25.6909 114.78C25.6909 114.058 25.9766 113.366 26.4852 112.855C26.9938 112.344 27.6835 112.058 28.4027 112.058H143.155C143.875 112.058 144.564 112.344 145.073 112.855C145.582 113.366 145.867 114.058 145.867 114.78C145.867 115.502 145.582 116.195 145.073 116.705C144.564 117.216 143.875 117.503 143.155 117.503Z"
          fill={colors.primary}
        />
        <path
          d="M80.7835 114.636H33.969V103.746C33.969 97.5129 36.4351 91.5355 40.8248 87.1283C45.2145 82.7211 51.1683 80.2451 57.3763 80.2451C63.5843 80.2451 69.538 82.7211 73.9277 87.1283C78.3174 91.5355 80.7835 97.5129 80.7835 103.746V114.636Z"
          fill={colors.primary}
        />
        <path
          d="M136.733 114.636H89.918V103.746C89.918 97.5129 92.3841 91.5355 96.7738 87.1283C101.164 82.7211 107.117 80.2451 113.325 80.2451C119.533 80.2451 125.487 82.7211 129.877 87.1283C134.266 91.5355 136.733 97.5129 136.733 103.746V114.636Z"
          fill={colors.primary}
        />
        <path
          d="M111.185 139.713H114.896V115.639H111.185V139.713Z"
          fill={colors.primary}
        />
        <path
          d="M100.623 139.583C100.623 139.986 106.246 139.739 113.183 139.739C120.119 139.739 125.743 139.986 125.743 139.583C125.743 139.181 120.119 135.988 113.183 135.988C106.246 135.988 100.623 139.181 100.623 139.583Z"
          fill={colors.primary}
        />
        <path
          d="M55.2356 139.713H58.9465V115.639H55.2356V139.713Z"
          fill={colors.primary}
        />
        <path
          d="M44.6738 139.583C44.6738 139.986 50.2971 139.739 57.2338 139.739C64.1705 139.739 69.7938 139.986 69.7938 139.583C69.7938 139.181 64.1705 135.988 57.2338 135.988C50.2971 135.988 44.6738 139.181 44.6738 139.583Z"
          fill={colors.primary}
        />
        <path
          d="M73.7696 136.431H77.2693L78.9341 122.879H73.769L73.7696 136.431Z"
          fill="#FACF9D"
        />
        <path
          d="M84.0186 139.693L73.0198 139.694L73.0196 135.427L79.7688 135.427C80.3269 135.427 80.8795 135.537 81.3951 135.752C81.9107 135.966 82.3791 136.28 82.7738 136.677C83.1684 137.073 83.4814 137.543 83.695 138.061C83.9086 138.578 84.0185 139.133 84.0186 139.693L84.0186 139.693Z"
          fill={colors.accent4}
        />
        <path
          d="M79.2327 134.471L82.5716 133.419L80.119 119.988L75.1909 121.541L79.2327 134.471Z"
          fill="#FACF9D"
        />
        <path
          d="M89.9839 134.502L79.4897 137.808L78.2173 133.738L84.6569 131.709C85.7323 131.37 86.8976 131.474 87.8966 131.998C88.8956 132.522 89.6464 133.422 89.9839 134.502Z"
          fill={colors.accent4}
        />
        <path
          d="M76.891 128.233L70.489 111.361C69.0527 111.7 57.008 114.376 52.9738 110.063C51.5913 108.585 51.3217 106.444 52.1724 103.699L54.5267 100.729L54.5999 100.732C55.49 100.779 76.4187 101.957 77.8533 108.295C79.063 113.639 81.2721 123.601 81.9203 126.527C81.986 126.83 81.9411 127.147 81.7936 127.419C81.6462 127.692 81.4061 127.903 81.117 128.012L78.5424 128.982C78.399 129.036 78.247 129.064 78.0937 129.064C77.8331 129.064 77.5786 128.984 77.3639 128.836C77.1492 128.688 76.9843 128.478 76.891 128.233Z"
          fill={colors.accent4}
        />
        <path
          d="M73.18 130.239L66.7781 113.367C65.3417 113.706 53.297 116.381 49.2629 112.069C47.8804 110.591 47.6107 108.45 48.4615 105.705L50.8158 102.734L50.8889 102.738C51.7791 102.785 72.7078 103.963 74.1424 110.301C75.352 115.644 77.5612 125.607 78.2094 128.533C78.2751 128.836 78.2301 129.153 78.0827 129.425C77.9352 129.698 77.6951 129.908 77.4061 130.018L74.8315 130.987C74.6881 131.042 74.536 131.069 74.3828 131.069C74.1222 131.069 73.8677 130.99 73.653 130.842C73.4382 130.694 73.2733 130.483 73.18 130.239Z"
          fill={colors.accent4}
        />
        <path
          d="M67.4214 97.505C66.4725 97.5047 65.5401 97.257 64.7152 96.7862C63.8904 96.3154 63.2014 95.6376 62.7158 94.8192L60.6826 91.3612L65.5152 88.7094L67.5671 91.5341L81.4898 90.0614C81.5575 89.9383 81.6338 89.8202 81.7179 89.7077C81.985 89.3548 82.3233 89.0623 82.7105 88.8493C83.0977 88.6362 83.5253 88.5075 83.9654 88.4714C84.4074 88.4333 84.8524 88.4907 85.2706 88.6396C85.6887 88.7885 86.0703 89.0255 86.3896 89.3347C86.9016 89.8276 87.2327 90.4799 87.329 91.1855C87.4254 91.8911 87.2815 92.6087 86.9205 93.2218C86.6947 93.6039 86.3911 93.9337 86.0294 94.1897C85.6678 94.4457 85.2564 94.6221 84.8221 94.7074C84.3879 94.7926 83.9405 94.7848 83.5095 94.6844C83.0785 94.5841 82.6734 94.3934 82.3209 94.1249C82.2392 94.0621 82.1608 93.9951 82.086 93.9242L68.7511 97.3377C68.3165 97.4489 67.8698 97.5051 67.4214 97.505Z"
          fill="#FACF9D"
        />
        <path
          d="M48.3711 106.595C48.1784 106.455 48.0279 106.265 47.9363 106.045C47.8447 105.825 47.8155 105.584 47.8519 105.349C48.6462 100.092 47.7323 92.5611 46.8258 87.1693C46.6402 86.0753 46.7124 84.9529 47.0366 83.892C47.3608 82.8311 47.928 81.8611 48.6928 81.0599C49.4575 80.2586 50.3985 79.6483 51.4404 79.2778C52.4823 78.9073 53.5962 78.787 54.6927 78.9264C55.6468 79.053 56.5919 79.24 57.5224 79.4865C59.068 79.8773 60.4353 80.7844 61.3993 82.0588C62.3632 83.3332 62.8666 84.899 62.8267 86.4987C62.6912 91.7016 63.7434 98.1933 64.2021 100.743C64.2334 100.918 64.2285 101.098 64.1875 101.271C64.1466 101.444 64.0705 101.607 63.9641 101.749C63.8598 101.89 63.7273 102.007 63.5752 102.093C63.423 102.18 63.2545 102.233 63.0805 102.25C57.9971 102.771 52.5152 105.26 49.7 106.697C49.5191 106.791 49.3185 106.84 49.1149 106.841C48.8473 106.84 48.5868 106.754 48.3711 106.595Z"
          fill={colors.accent1}
        />
        <path
          d="M62.093 93.0365C61.9359 92.9746 61.7926 92.882 61.6714 92.764C61.5502 92.6461 61.4535 92.5052 61.3869 92.3494L58.5519 85.7744C58.3803 85.3771 58.2883 84.9498 58.2812 84.5168C58.2741 84.0838 58.3521 83.6537 58.5107 83.251C58.6693 82.8483 58.9053 82.4809 59.2054 82.1699C59.5055 81.8588 59.8637 81.6102 60.2595 81.4382C60.6553 81.2661 61.081 81.1741 61.5123 81.1674C61.9436 81.1606 62.3719 81.2393 62.7729 81.3988C63.1739 81.5584 63.5396 81.7957 63.8492 82.0972C64.1587 82.3988 64.4061 82.7586 64.5771 83.1561L67.4121 89.7312C67.547 90.0452 67.5523 90.4 67.427 90.7179C67.3017 91.0357 67.0559 91.2908 66.7436 91.427L63.0761 93.0209C62.9214 93.0886 62.7549 93.1249 62.5861 93.1276C62.4174 93.1303 62.2498 93.0994 62.093 93.0365H62.093Z"
          fill={colors.accent1}
        />
        <path
          d="M54.171 76.6903C58.0431 76.6903 61.182 73.5388 61.182 69.6513C61.182 65.7638 58.0431 62.6123 54.171 62.6123C50.2989 62.6123 47.1599 65.7638 47.1599 69.6513C47.1599 73.5388 50.2989 76.6903 54.171 76.6903Z"
          fill="#FACF9D"
        />
        <path
          d="M49.934 75.905C49.8347 76.0201 49.7134 76.114 49.5773 76.1812C49.4412 76.2484 49.2931 76.2876 49.1416 76.2963C48.9902 76.305 48.8385 76.2832 48.6957 76.2321C48.5528 76.181 48.4216 76.1016 48.3098 75.9987C46.7464 74.7281 45.5065 73.1025 44.6924 71.2559C43.7809 69.0747 43.6474 66.4128 44.6847 64.3682C45.5616 62.6399 47.1358 61.5397 48.7073 60.6405C49.3098 60.2594 49.97 59.9792 50.6621 59.811C51.0116 59.7313 51.3739 59.7261 51.7256 59.7955C52.0774 59.8649 52.4107 60.0076 52.7042 60.2142C53.0156 60.4519 53.2566 60.7704 53.4014 61.1353C53.5461 61.5002 53.5891 61.8978 53.5257 62.2854C54.2446 61.3347 55.2137 60.6045 56.3238 60.1771C57.0049 59.8828 57.7659 59.8316 58.48 60.0321C59.4893 60.3703 60.2363 61.3935 60.652 62.4928C60.9882 63.3344 61.1113 64.2466 61.0104 65.1477C60.9664 65.578 60.8356 65.9948 60.6261 66.3727C60.4166 66.7507 60.1327 67.0819 59.7917 67.3463C58.9685 67.9166 57.908 67.8586 56.9506 67.6062C55.9932 67.3538 55.064 66.9259 54.09 66.8066C53.1159 66.6873 52.0363 66.9459 51.456 67.8182C51.0473 68.4325 50.9509 69.2417 50.8675 70.013L50.4338 74.0249C50.4032 74.5989 50.28 75.1641 50.0691 75.6985C50.0334 75.773 49.988 75.8425 49.934 75.905Z"
          fill={colors.accent4}
        />
        <path
          d="M64.921 104.855C64.5041 104.709 64.1226 104.476 63.8012 104.172C63.4799 103.868 63.2259 103.5 63.0558 103.09C63.0166 102.995 62.9823 102.897 62.9533 102.798L48.0177 98.5301C47.3759 98.3441 46.811 97.9552 46.4067 97.4212C46.0025 96.8873 45.7805 96.2367 45.7738 95.566L45.7373 89.7764L51.2425 89.9243L51.6013 93.4019L64.3728 99.1586C64.493 99.0865 64.6181 99.0228 64.7471 98.9682H64.7472C65.2177 98.7701 65.729 98.69 66.2372 98.7348C66.7454 98.7796 67.235 98.9479 67.6639 99.2251C68.0928 99.5024 68.4481 99.8803 68.6989 100.326C68.9498 100.772 69.0888 101.273 69.1039 101.785C69.119 102.297 69.0098 102.804 68.7857 103.265C68.5615 103.725 68.2292 104.123 67.8174 104.425C67.4056 104.728 66.9267 104.925 66.4221 105C65.9174 105.074 65.4022 105.025 64.921 104.855V104.855Z"
          fill="#FACF9D"
        />
        <path
          d="M45.823 92.3186C45.7109 92.1919 45.6249 92.0442 45.5701 91.8839C45.5152 91.7236 45.4925 91.554 45.5034 91.3849L45.9413 84.2337C45.967 83.8012 46.0775 83.3781 46.2663 82.9885C46.4551 82.5989 46.7186 82.2505 47.0417 81.9632C47.3648 81.676 47.7411 81.4555 48.1491 81.3144C48.5572 81.1733 48.9889 81.1144 49.4196 81.141C49.8504 81.1676 50.2717 81.2792 50.6594 81.4694C51.0471 81.6597 51.3937 81.9248 51.6792 82.2497C51.9648 82.5745 52.1838 82.9527 52.3236 83.3626C52.4634 83.7725 52.5214 84.2061 52.4942 84.6385L52.0563 91.7897C52.035 92.1309 51.8797 92.4497 51.6246 92.6762C51.3695 92.9026 51.0353 93.0183 50.6954 92.9977L46.7066 92.7515C46.5381 92.7416 46.3733 92.6983 46.2216 92.6239C46.0699 92.5496 45.9345 92.4458 45.823 92.3186Z"
          fill={colors.accent1}
        />
        <path
          d="M84.7996 95.8647H80.7645C80.635 95.8651 80.5101 95.817 80.4142 95.7296C80.3183 95.6423 80.2583 95.5221 80.2462 95.3927L79.4385 87.0811H86.1256L85.3179 95.3926C85.3057 95.5221 85.2458 95.6422 85.1499 95.7296C85.054 95.8169 84.9291 95.8651 84.7996 95.8647Z"
          fill={colors.secondary}
        />
        <path
          d="M86.1147 88.1267H79.449C79.3109 88.1265 79.1786 88.0714 79.0809 87.9733C78.9833 87.8753 78.9284 87.7424 78.9282 87.6038V86.349C78.9284 86.2104 78.9833 86.0775 79.0809 85.9795C79.1786 85.8815 79.3109 85.8263 79.449 85.8262H86.1147C86.2528 85.8263 86.3852 85.8815 86.4828 85.9795C86.5804 86.0775 86.6353 86.2104 86.6355 86.349V87.6038C86.6353 87.7424 86.5804 87.8753 86.4828 87.9733C86.3852 88.0714 86.2528 88.1265 86.1147 88.1267Z"
          fill={colors.accent4}
        />
        <path
          d="M24.1286 88.4141H15.9893C15.7495 88.4141 15.5194 88.5097 15.3498 88.68C15.1802 88.8503 15.085 89.0812 15.085 89.322V106.573H16.4415V90.684C16.4415 90.4432 16.5368 90.2122 16.7064 90.0419C16.876 89.8717 17.106 89.776 17.3459 89.776H23.2242C23.4641 89.776 23.6941 89.8717 23.8637 90.0419C24.0333 90.2122 24.1286 90.4432 24.1286 90.684V106.573H25.4851V89.322C25.4851 89.0812 25.3898 88.8503 25.2202 88.68C25.0506 88.5097 24.8206 88.4141 24.5807 88.4141H24.1286Z"
          fill={colors.secondary}
        />
        <path
          d="M29.5548 139.713H10.1111C9.09209 139.712 8.11513 139.305 7.39458 138.582C6.67402 137.858 6.26872 136.877 6.26758 135.854V107.253C6.26872 106.23 6.67402 105.249 7.39458 104.526C8.11513 103.803 9.09209 103.396 10.1111 103.395H29.5548C30.5739 103.396 31.5508 103.803 32.2714 104.526C32.9919 105.249 33.3972 106.23 33.3984 107.253V135.854C33.3972 136.877 32.9919 137.858 32.2714 138.582C31.5508 139.305 30.5739 139.712 29.5548 139.713Z"
          fill={colors.secondary}
        />
        <path
          d="M6.62123 127.195C5.29958 127.195 4.58643 124.447 4.58643 121.861C4.58643 119.275 5.29957 116.526 6.62123 116.526C7.94289 116.526 8.65604 119.275 8.65604 121.861C8.65604 124.447 7.94288 127.195 6.62123 127.195ZM6.62123 116.98C5.87264 116.98 5.0386 118.985 5.0386 121.861C5.0386 124.737 5.87264 126.741 6.62123 126.741C7.36982 126.741 8.20385 124.737 8.20385 121.861C8.20385 118.985 7.36982 116.98 6.62123 116.98Z"
          fill={colors.secondary}
        />
        <path
          d="M28.1981 122.688H24.5806C24.2809 122.688 23.9936 122.568 23.7817 122.355C23.5698 122.143 23.4505 121.854 23.4502 121.553V118.829C23.4505 118.528 23.5698 118.24 23.7817 118.027C23.9936 117.814 24.2809 117.695 24.5806 117.694H28.1981C28.4978 117.695 28.7851 117.814 28.997 118.027C29.209 118.24 29.3282 118.528 29.3285 118.829V121.553C29.3282 121.854 29.209 122.143 28.997 122.355C28.7851 122.568 28.4978 122.688 28.1981 122.688Z"
          fill="white"
        />
        <path
          d="M29.3286 137.789H8.65653C8.5366 137.789 8.42159 137.742 8.33679 137.656C8.25199 137.571 8.20435 137.456 8.20435 137.335V105.664C8.20435 105.544 8.25199 105.428 8.33679 105.343C8.42159 105.258 8.5366 105.21 8.65653 105.21H29.3286C29.4486 105.21 29.5636 105.258 29.6484 105.343C29.7332 105.428 29.7808 105.544 29.7808 105.664C29.7808 105.784 29.7332 105.9 29.6484 105.985C29.5636 106.07 29.4486 106.118 29.3286 106.118H9.10871V136.881H29.3286C29.4484 136.882 29.5631 136.93 29.6477 137.015C29.7322 137.1 29.7797 137.215 29.7797 137.335C29.7797 137.456 29.7322 137.571 29.6477 137.656C29.5631 137.741 29.4484 137.789 29.3286 137.789Z"
          fill="white"
        />
        <path
          d="M138.368 52.8629C138.103 52.6325 137.794 52.4567 137.461 52.3451C136.716 52.1136 135.934 52.0282 135.156 52.0936C134.586 52.1258 134.02 52.2051 133.462 52.3306L107.439 59.7222C106.947 59.8608 106.464 60.0223 105.989 60.2067C104.311 59.5475 99.5689 57.6409 99.0491 56.9568L97.0093 57.5557L100.41 62.6518C100.324 62.6895 100.251 62.7525 100.201 62.8323C100.152 62.9122 100.127 63.0053 100.131 63.0995C100.134 63.1936 100.167 63.2844 100.223 63.36C100.279 63.4356 100.356 63.4924 100.445 63.523C104.365 64.8745 110.286 63.0801 110.286 63.0801L133.025 56.5576C133.025 56.5576 139.888 54.2268 138.368 52.8629Z"
          fill="white"
        />
        <path
          d="M125.474 56.9502L110.694 60.9567L109.821 62.7101L125.474 56.9502Z"
          fill="#F2F2F2"
        />
        <path
          d="M156.715 140H0.285455C0.209747 140 0.137145 139.97 0.0836118 139.916C0.0300787 139.862 0 139.789 0 139.713C0 139.637 0.0300787 139.564 0.0836118 139.511C0.137145 139.457 0.209747 139.427 0.285455 139.427H156.715C156.79 139.427 156.863 139.457 156.916 139.511C156.97 139.564 157 139.637 157 139.713C157 139.789 156.97 139.862 156.916 139.916C156.863 139.97 156.79 140 156.715 140Z"
          fill={colors.primary}
        />
        <path
          d="M125.172 25.0771C124.585 25.077 124.004 25.1985 123.466 25.4341C122.928 25.6697 122.444 26.0143 122.045 26.4463C121.568 26.2563 121.053 26.1862 120.544 26.2422C120.035 26.2983 119.547 26.4787 119.123 26.7678C118.7 27.0568 118.353 27.4457 118.114 27.9004C117.874 28.3551 117.749 28.8617 117.75 29.376H129.454C129.454 28.2359 129.003 27.1425 128.2 26.3363C127.397 25.5301 126.307 25.0771 125.172 25.0771Z"
          fill="white"
        />
        <path
          d="M62.372 11.0332C61.785 11.0331 61.2042 11.1546 60.666 11.3902C60.1279 11.6258 59.6441 11.9704 59.2447 12.4024C58.7687 12.2123 58.2535 12.1423 57.7443 12.1983C57.2351 12.2543 56.7473 12.4348 56.3236 12.7238C55.8999 13.0129 55.5532 13.4017 55.3137 13.8564C55.0743 14.3111 54.9495 14.8178 54.9502 15.3321H66.6538C66.6538 14.1919 66.2027 13.0985 65.3997 12.2923C64.5967 11.4861 63.5076 11.0332 62.372 11.0332Z"
          fill="white"
        />
        <path
          d="M42.9609 48.5771C42.3738 48.577 41.793 48.6985 41.2549 48.9341C40.7168 49.1697 40.2329 49.5143 39.8336 49.9463C39.3576 49.7563 38.8424 49.6862 38.3332 49.7422C37.824 49.7983 37.3362 49.9787 36.9125 50.2678C36.4887 50.5568 36.142 50.9457 35.9026 51.4004C35.6632 51.8551 35.5384 52.3617 35.5391 52.876H47.2427C47.2427 52.3115 47.1319 51.7525 46.9168 51.2309C46.7016 50.7093 46.3862 50.2354 45.9886 49.8363C45.591 49.4371 45.119 49.1204 44.5995 48.9044C44.08 48.6883 43.5232 48.5771 42.9609 48.5771Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_766_11142">
          <rect width="157" height="140" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
