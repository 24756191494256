import * as FullStory from "@fullstory/browser";

export const sendSessionLoadedEvent = (session_id, payload) => {
  FullStory.event("SessionLoaded", {
    session_id: session_id,
    ...payload,
  });
};

export const sendSeatMapOpenedEvent = (session_id, payload) => {
  FullStory.event("SeatMapOpened", {
    session_id: session_id,
    ...payload,
  });
};

export const sendSeatMapLoadedEvent = (session_id, payload) => {
  FullStory.event("SeatMapLoaded", {
    session_id: session_id,
    ...payload,
  });
};

export const sendSeatMapErrorEvent = (session_id, payload) => {
  FullStory.event("SeatMapError", {
    session_id: session_id,
    ...payload,
  });
};

export const sendCheckoutButtonClickedEvent = (session_id, payload) => {
  FullStory.event("CheckoutButtonClicked", {
    session_id: session_id,
    ...payload,
  });
};

export const sendStripePaymentButtonClickedEvent = (session_id, payload) => {
  FullStory.event("StripePaymentButtonClicked", {
    session_id: session_id,
    ...payload,
  });
};

export const sendStripePaymentErroredEvent = (session_id, payload) => {
  FullStory.event("StripePaymentErrored", {
    session_id: session_id,
    ...payload,
  });
};

export const sendAncillaryPageLoadedEvent = (session_id, payload) => {
  FullStory.event("AncillaryPageLoaded", {
    session_id: session_id,
    ...payload,
  });
};

export const sendCheckPageLoadedEvent = (session_id, payload) => {
  FullStory.event("CheckoutPageLoaded", {
    session_id: session_id,
    ...payload,
  });
};

export const sendConfirmationPageLoadedEvent = (session_id, payload) => {
  FullStory.event("ConnfirmationPageLoaded", {
    session_id: session_id,
    ...payload,
  });
};
