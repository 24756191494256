import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { HelpButtons } from "../components/Specialized/HelpButtons";
import { Header } from "../components/Common/Header";
import { ErrorSVG } from "../assets/dynamic/ErrorSVG";

export default function NotFound() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);

  return (
    <>
      <Header />
      <div className="text-partner-textColorAccent mt-16 md:mt-28 grid md:grid-cols-2 md:gap-6 lg:gap-14 xl:gap-28">
        <div>
          <p className="text-5xl mb-6">
            {Intl.NumberFormat(navigator.language).format(404)}
          </p>
          <p className="text-xl mb-4">{t("page-not-found")}</p>
          <p className="text-xl mb-6">{t("404-message")}</p>
          <div className="mt-8 hidden md:block">
            <HelpButtons />
          </div>
        </div>
        <div className="md:w-[30vw] max-w-[400px] md:min-w-[300px]">
          <ErrorSVG />
        </div>
        <div className="mt-8 md:hidden">
          <HelpButtons />
        </div>
      </div>
    </>
  );
}
