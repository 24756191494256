import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ErrorContext } from "./context/ErrorContext";
import { Ancillaries } from "./pages/Ancillaries";
import { Checkout } from "./pages/Checkout";
import { Confirmation } from "./pages/Confirmation";
import NotFound from "./pages/NotFound";
import { ErrorPage } from "./pages/ErrorPage";
import { ProtectedRoute } from "./layout/ProtectedRoute";
import BookingLogin from "./pages/BookingLogin";
import { CustomizationProvider } from "./context/CustomizationContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";

function App() {
  const { errorType } = useContext(ErrorContext);
  // Warning there is an assumption that sessionId is second field
  // in all paths. There is a design choice that was made early on that necistates this
  // I will refactor this at somepoint
  return (
    <div className="mx-5 sm:mx-auto sm:w-4/5 md:w-[85%] lg:w-4/5 xl:w-3/4 2xl:w-[65%] mt-8 xl:mt-12 mb-10 overflow-hidden">
      <ErrorBoundary>
        <CustomizationProvider>
          <BrowserRouter>
            {errorType === "404" ? (
              <NotFound />
            ) : errorType === "generic" ? (
              <ErrorPage />
            ) : (
              <Routes>
                <Route
                  path="/ancillaries/:sessionId"
                  element={<BookingLogin />}
                />
                <Route
                  path="/ancillaries/:sessionId/booking"
                  element={
                    <ProtectedRoute>
                      <Ancillaries />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/checkout/:sessionId"
                  element={
                    <ProtectedRoute>
                      <Checkout />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/confirmation/:sessionId"
                  element={
                    <ProtectedRoute>
                      <Confirmation />
                    </ProtectedRoute>
                  }
                />
                <Route path="/" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            )}
          </BrowserRouter>
        </CustomizationProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
