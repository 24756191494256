import React, { useContext, useEffect, useState } from "react";
import { Header } from "../components/Common/Header";
import Divider from "../components/Common/Divider";
import { PostBookingContext } from "../context/PostBookingContext";
import { OrderSummary } from "../components/Specialized/OrderSummary";
import { TripCard } from "../components/TripCard/TripCard";
import { FlightDirection } from "../types/TripCardTypes";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CheckmarkIcon } from "../assets/dynamic/CheckmarkIcon";
import { ConfirmationWaitingSVG } from "../assets/dynamic/ConfirmationWaitingSVG";
import { sendConfirmationPageLoadedEvent } from "../utils/fullstory_events/events";
import { AuthContext } from "../context/AuthContext";
import { getPaymentDetails, IPaymentMethodDetailsRes } from "../api/payments";
import { ErrorContext } from "../context/ErrorContext";

export const Confirmation = () => {
  const { getDestinationCityName } = useContext(PostBookingContext);
  const { setError } = useContext(ErrorContext);
  const [paymentDetails, setPaymentDetails] = useState<
    IPaymentMethodDetailsRes | undefined
  >(undefined);
  const { t, i18n } = useTranslation();
  const { sessionId } = useParams();

  const auth = useContext(AuthContext);
  const cityName = getDestinationCityName();
  const headerText = t("confirmation-header", { cityName }).split(cityName);
  const currentDate = new Date().toLocaleDateString(i18n.language, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  useEffect(() => {
    sendConfirmationPageLoadedEvent(sessionId, {});
  }, []);

  useEffect(() => {
    if (auth.accessToken === undefined) return;
    getPaymentDetails(auth.accessToken, auth.refreshToken)
      .then((val) => setPaymentDetails(val))
      .catch(() => setError("generic"));
  }, [auth.accessToken, auth.refreshToken]);

  if (paymentDetails === undefined) {
    return (
      <div>
        <SLoader />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="flex flex-row justify-between items-end mt-12">
        <div>
          <CheckmarkIcon />
          <p className=" text-partner-textColor my-4 mr-2 sm:text-xl md:text-[2.5vw] xl:text-3xl">
            {headerText[0]}
            <span className="text-partner-secondary font-semibold">
              {cityName}
            </span>
            {headerText[1]}
          </p>
        </div>
        <div className="w-[30vw] max-w-[240px] min-w-[157px]">
          <ConfirmationWaitingSVG />
        </div>
      </div>
      <div className="bg-partner-primary mt-[-1px]">
        <Divider />
      </div>
      <div className="flex flex-col md:flex-row-reverse gap-8 lg:gap-12 mt-6">
        <div className="w-full">
          <OrderSummary title={t("order-summary")} date={currentDate} />
          <p className="text-partner-textColor font-medium mt-2">
            {t("card-number", {
              cardBrand: paymentDetails.paymentMethodDetails.card.brand,
              cardNumber: paymentDetails.paymentMethodDetails.card.last4,
            })}
          </p>
        </div>
        <div className="w-full flex flex-col gap-6">
          <TripCard direction={FlightDirection.Depart} />
          <TripCard direction={FlightDirection.Return} />
        </div>
      </div>
    </div>
  );
};

import ScaleLoader from "react-spinners/ScaleLoader";

const SLoader = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-6 animate-pulse">
      <div className="flex flex-row justify-center items-center">
        <div className="mt-8 text-md sm:text-md md:text-md xl:text-md text-partner-textColor">
          {t("this-may-take-a-moment")}
        </div>
      </div>
      <br></br>
      <br></br>
      <div className="flex flex-row justify-center items-center">
        <ScaleLoader color="#7d161a" />
      </div>
      <br></br>
      <br></br>
      <div className={"bg-loading-dark"}>
        <Divider />
      </div>
    </div>
  );
};
