import React, { useContext } from "react";
import { ErrorContext } from "../../context/ErrorContext";
import { CustomizationContext } from "../../context/CustomizationContext";

export const Header = () => {
  const { logoUrl } = useContext(CustomizationContext);
  const { errorType } = useContext(ErrorContext);

  const is404 = errorType === "404";

  return logoUrl === undefined ? (
    <HeaderLoader />
  ) : (
    <div
      className={`flex flex-row items-center ${
        is404 ? "justify-end" : "justify-between"
      }`}
    >
      {!is404 && <img className="max-h-12" src={logoUrl} />}
    </div>
  );
};

const HeaderLoader = () => {
  return (
    <div className="flex flex-row justify-between items-center animate-pulse">
      <div className="w-10 h-8 bg-loading-light"></div>
      <div className=" w-24 h-2 md:w-60 md:h-3 bg-loading-light rounded-sm"></div>
    </div>
  );
};
