import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const CheckmarkIcon = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill={colors.primary} />
      <g clipPath="url(#clip0_766_11183)">
        <path
          d="M13.9999 18.7799L11.2199 15.9999L10.2732 16.9399L13.9999 20.6666L21.9999 12.6666L21.0599 11.7266L13.9999 18.7799Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_766_11183">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
