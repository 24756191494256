import React, { useContext, useState, useEffect } from "react";
import Divider from "../components/Common/Divider";
import { PostBookingContext } from "../context/PostBookingContext";
import { Button } from "../components/Common/Button";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import { Header } from "../components/Common/Header";
import GordianWidget from "../components/Specialized/GordianWidget";
import { BasketContext } from "../context/BasketContext";
import { AuthContext } from "../context/AuthContext";
import { TripCard } from "../components/TripCard/TripCard";
import { FlightDirection } from "../types/TripCardTypes";
import { AncillariesHeaderProps } from "../types/AncillariesHeader";
import { useTranslation } from "react-i18next";
import { TravelerSVG } from "../assets/dynamic/TravelerSVG";
import {
  sendSessionLoadedEvent,
  sendAncillaryPageLoadedEvent,
  sendSeatMapOpenedEvent,
  sendCheckoutButtonClickedEvent,
} from "../utils/fullstory_events/events";
import { Footer } from "../components/Common/Footer";

export const Ancillaries = () => {
  const {
    toggleOpenUpsell,
    seat = true,
    bag = true,
    trip,
  } = useContext(PostBookingContext);
  const { accessToken, refreshToken } = useContext(AuthContext);
  const { isBasketEmpty } = useContext(BasketContext);
  const [upsellType, setUpsellType] = useState(["seats"]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { sessionId } = useParams();
  const isSingleLegJourney = trip && trip.journeys.length === 1;
  useEffect(() => {
    sendSessionLoadedEvent(sessionId, {});
    sendAncillaryPageLoadedEvent(sessionId, {});
  }, []);
  const handleUpsellButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (
      e.currentTarget.id === "seat-upsell-button" &&
      !upsellType.includes("seats")
    ) {
      setUpsellType(["seats"]);
    } else if (
      e.currentTarget.id === "bag-upsell-button" &&
      !upsellType.includes("bags")
    ) {
      setUpsellType(["bags"]);
    }
    toggleOpenUpsell();
    sendSeatMapOpenedEvent(sessionId, {});
  };

  const handleCheckoutButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    sendCheckoutButtonClickedEvent(sessionId, {});
    const queries = createSearchParams({
      access_token: accessToken,
      refresh_token: refreshToken,
    });
    navigate(`/checkout/${sessionId}/?${queries.toString()}`, {
      state: "from ancillaries",
    });
  };

  return (
    <div
      id="ancillaries"
      className="flex flex-col justify-between min-h-[90vh]"
    >
      <div>
        <GordianWidget upsellType={upsellType} sessionId={sessionId} />
        <Header />
        <div
          className={
            isSingleLegJourney
              ? "md:mt-5 md:grid md:grid-cols-2 md:gap-7 lg:mt-8 xl:mt-10 lg:gap-12 xl:gap-16"
              : ""
          }
        >
          {trip ? (
            <AncillariesHeader isSingleLegJourney={isSingleLegJourney} />
          ) : (
            <AncillariesHeaderLoader />
          )}
          <div
            className={`mt-6 grid gap-6 lg:gap-x-8 lg:gap-y-6 md:gap-x-6 md:gap-y-4 ${
              isSingleLegJourney ? "md:grid-cols-1 h-fit" : "md:grid-cols-2"
            }`}
          >
            <TripCard direction={FlightDirection.Depart} />
            <TripCard direction={FlightDirection.Return} />
            <div
              className={`grid gap-2 ${
                !isSingleLegJourney
                  ? "md:grid-cols-2 md:col-span-full lg:gap-8 md:gap-6"
                  : ""
              }`}
            >
              <div className={seat && bag ? "grid grid-cols-2 gap-2" : ""}>
                {seat && (
                  <Button
                    id="seat-upsell-button"
                    onClick={handleUpsellButtonClick}
                    buttonText={
                      isBasketEmpty ? t("select-seat") : t("edit-seat")
                    }
                    isLoading={trip === undefined}
                  />
                )}
                {bag && (
                  <Button
                    id="bag-upsell-button"
                    onClick={handleUpsellButtonClick}
                    buttonText={isBasketEmpty ? t("select-bag") : t("edit-bag")}
                    isLoading={trip === undefined}
                  />
                )}
              </div>
              {!isBasketEmpty && (
                <div className="col-span-full md:col-span-1">
                  <Button
                    onClick={handleCheckoutButtonClick}
                    buttonText={
                      i18n.exists("checkout-button")
                        ? t("checkout-button")
                        : t("checkout")
                    }
                    isSecondary={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

const AncillariesHeader = ({ isSingleLegJourney }: AncillariesHeaderProps) => {
  const { getDestinationCityName } = useContext(PostBookingContext);
  const { t } = useTranslation();

  const cityName = getDestinationCityName();
  const headerText = t("ancillaries-header", { cityName }).split(cityName);

  return (
    <div className="mt-6">
      <div
        className={`flex ${isSingleLegJourney ? "md:flex-col-reverse" : ""}`}
      >
        <p
          className={`self-end text-partner-textColor font-medium sm:w-full sm:text-xl md:text-[2.5vw] xl:text-3xl mb-5 ${
            isSingleLegJourney ? "md:mb-0 md:mt-5" : ""
          }`}
        >
          {headerText[0] + " "}
          <span className="text-partner-secondary font-semibold">
            {cityName}
          </span>
          {headerText[1]}
        </p>
        {isSingleLegJourney && (
          <div className="bg-partner-primary">
            <Divider />
          </div>
        )}

        <div className="w-[30vw] max-w-[255px] min-w-[165px]">
          <TravelerSVG />
        </div>
      </div>
      <div
        className={`bg-partner-primary ${
          isSingleLegJourney ? "md:hidden" : ""
        }`}
      >
        <Divider />
      </div>
    </div>
  );
};

const AncillariesHeaderLoader = () => {
  return (
    <div className="mt-6 animate-pulse">
      <div className="flex flex-row items-center justify-between mb-8">
        <div className="w-1/2 md:w-3/5 lg:w-3/4 space-y-2">
          <div className="w-full h-2 md:h-3 lg:h-4 xl:h-5 rounded-sm bg-loading-dark"></div>
          <div className="w-4/5 h-2 md:h-3 lg:h-4 xl:h-5 rounded-sm bg-loading-light"></div>
          <div className="w-4/5 h-2 md:h-3 lg:hidden rounded-sm bg-loading-light"></div>
        </div>
        <div className="w-[25vw] h-[25vw] max-w-[160px] max-h-[160px] bg-loading-dark rounded-full"></div>
      </div>
      <div className={"bg-loading-dark"}>
        <Divider />
      </div>
    </div>
  );
};
