import React, { useContext, useState } from "react";
import {
  FlightDirection,
  JourneyDirectionTagProps,
  TripCardProps,
} from "../../types/TripCardTypes";
import { TripCardHeader } from "./TripCardHeader";
import { TripCardPassengerList } from "./TripCardPassengerList";
import { SelectionInfo } from "./SelectionInfo";
import { BasketContext } from "../../context/BasketContext";
import { PostBookingContext } from "../../context/PostBookingContext";
import { useTranslation } from "react-i18next";

export const TripCard = ({ direction }: TripCardProps) => {
  const { getSelectedJourneyProductsSummary, getCurrency } =
    useContext(BasketContext);
  const { trip } = useContext(PostBookingContext);
  const [isCardExpanded, setIsCardExpanded] = useState(false);

  const onCardTopClick = () => {
    setIsCardExpanded((current) => !current);
  };

  return !trip ? (
    <TripCardLoader />
  ) : (direction === FlightDirection.Return && trip.journeys.length > 1) ||
    direction === FlightDirection.Depart ? (
    <div className="trip-card w-full">
      <JourneyDirectionTag direction={direction} />
      <div className="trip-box border border-neutral-300 rounded-b-2xl rounded-tr-2xl">
        <TripCardHeader
          direction={direction}
          isCardExpanded={isCardExpanded}
          onCardTopClick={onCardTopClick}
        />
        {isCardExpanded && <TripCardPassengerList direction={direction} />}
        {!isCardExpanded && (
          <div className="px-4 pb-4">
            <SelectionInfo
              products={getSelectedJourneyProductsSummary(direction)}
              currency={getCurrency()}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
};

const JourneyDirectionTag = ({ direction }: JourneyDirectionTagProps) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex w-fit min-w-[48px] h-5 items-center justify-center rounded-t ${
        direction ? "bg-partner-secondary" : "bg-loading-dark"
      }`}
    >
      {direction && (
        <p className="text-white text-xs font-medium px-1">{t(direction)}</p>
      )}
    </div>
  );
};

const TripCardLoader = () => {
  return (
    <div className="w-full animate-pulse">
      <JourneyDirectionTag />
      <div className="flex flex-col border border-loading-dark rounded-b-2xl rounded-tr-2xl px-4 py-6 gap-6">
        <div className="flex flex-row w-full justify-between items-center">
          <div className="h-2 w-2/5 bg-loading-light rounded-sm"></div>
          <div className="h-6 w-6 bg-loading-dark rounded-full mr-4"></div>
        </div>
        <div className="h-2 w-3/5 bg-loading-light rounded-sm -mt-2"></div>
        <div className="space-y-1">
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row w-full items-center gap-4">
              <div className="h-4 w-4 bg-loading-dark rounded"></div>
              <div className="h-2 w-1/3 bg-loading-light rounded-sm"></div>
            </div>
            <div className="h-2 w-1/5 bg-loading-light rounded-sm"></div>
          </div>
          <div className="flex flex-row justify-between items-center">
            <div className="flex flex-row w-full items-center gap-4">
              <div className="h-4 w-4 bg-loading-dark rounded"></div>
              <div className="h-2 w-1/3 bg-loading-light rounded-sm"></div>
            </div>
            <div className="h-2 w-1/5 bg-loading-dark rounded-sm"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
