import request, { getAuthorizationHeader } from "./httpClient";

export interface PaymentStatus {
  paymentMethod: string;
  paymentStatus: string;
  stripePaymentIntentStatus: string;
  payment: PaymentResponse;
  sessionStatus: string;
  allowCheckout: boolean;
}

interface PaymentResponse {
  paymentMethod: string;
  paymentMethodDetails: {
    card: {
      brand: string;
      checks: {
        addressLine1Check: string;
        addressPostalCodeCheck: string;
        cvcCheck: string;
      };
      country: string;
      expMonth: number;
      expYear: number;
      fingerprint: number;
      funding: string;
      installments: string;
      last4: string;
      mandate: string;
      network: string;
      threeDSecure: string;
      wallet: string;
    };
    type: string;
  };
}

type PaymentStatusType = "complete" | "uninitiated" | "processing";
type PaymentGatewayType = "stripe";

export interface IPaymentStatus {
  allowCheckout: boolean;
  paymentStatus: PaymentStatusType;
  paymentGateway: PaymentGatewayType;
  isSandbox: boolean;
}

export interface PaymentGateway {
  payment_gateway: string;
  live: boolean;
}

interface IStripePaymentIntentRes {
  paymentIntentClientSecret: string;
  paymentIntentId: string;
}

interface ICardDetails {
  brand: string;
  last4: string;
}

interface IPaymentDetails {
  card: ICardDetails;
}

export interface IPaymentMethodDetailsRes {
  paymentMethod: string;
  paymentMethodDetails: IPaymentDetails;
}

export const getStripePaymentIntentSecret = async (
  accessToken: string,
  refreshToken: string
): Promise<IStripePaymentIntentRes> => {
  const response = await request({
    endpoint: `payments/stripe/intent`,
    method: "POST",
    headers: getAuthorizationHeader(accessToken, refreshToken),
  });

  return response as unknown as IStripePaymentIntentRes;
};

export const getPaymentStatus = async (
  accessToken: string,
  refreshToken: string
): Promise<IPaymentStatus> => {
  const response = (await request({
    endpoint: `payments/status`,
    method: "GET",
    headers: getAuthorizationHeader(accessToken, refreshToken),
  })) as unknown as IPaymentStatus;

  return response;
};

export const getPaymentDetails = async (
  accessToken: string,
  refreshToken: string
) => {
  const response = await request({
    endpoint: "/payments/details",
    method: "GET",
    headers: getAuthorizationHeader(accessToken, refreshToken),
  });

  return response as unknown as IPaymentMethodDetailsRes;
};
