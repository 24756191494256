import React, { createContext, useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { getPaymentStatus, IPaymentStatus } from "../api/payments";
import { getSessionId } from "../utils/urlUtils";
import { AuthContext } from "./AuthContext";

// Below is what PaymentStatusContext provides to it's children
interface PaymentStatusContextInterface {
  paymentStatus: IPaymentStatus;
}

export const PaymentStatusContext =
  createContext<PaymentStatusContextInterface | null>(null);

interface PaymentStatusProviderProps {
  children: JSX.Element;
}

export const PaymentStatusProvider = ({
  children,
}: PaymentStatusProviderProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState({} as IPaymentStatus);
  const auth = useContext(AuthContext);

  useEffect(() => {
    // we will need to refactor this at some point to useParam from react router
    // and not the location pathName
    // to make the session id based on query rather than a path param
    const sessionId = getSessionId(window.location.pathname);
    const isConfirmationPage = location.pathname.includes("confirmation");
    const isCheckoutPage = location.pathname.includes("checkout");
    if (isCheckoutPage && !location.state) {
      // Prevent users from going straight to checkout page
      navigate("/ancillaries/" + sessionId, { replace: true });
    } else {
      getPaymentStatus(auth.accessToken, auth.refreshToken).then(
        (paymentStatusResponse) => {
          setPaymentStatus(paymentStatusResponse);
          if (!paymentStatusResponse.allowCheckout && !isConfirmationPage) {
            const queries = createSearchParams({
              access_token: auth.accessToken,
              refresh_token: auth.refreshToken,
            });
            navigate(`/confirmation/${sessionId}?${queries.toString()}`, {
              replace: true,
            });
          } else if (
            paymentStatusResponse.allowCheckout &&
            !location.search.includes("redirect_status") &&
            isConfirmationPage
          ) {
            // Prevent users from going to confirmation if allowCheckout = true
            navigate("/ancillaries/" + sessionId, { replace: true });
          }
        }
      );
    }
  }, [location, auth.accessToken, auth.refreshToken]);

  return (
    <PaymentStatusContext.Provider
      value={{
        paymentStatus,
      }}
    >
      {children}
    </PaymentStatusContext.Provider>
  );
};
