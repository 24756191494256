import { PostBookingSession } from "../types/PostBookingSession";
import request, { getAuthorizationHeader } from "./httpClient";

const getPostBookingSession = async (
  postBookingSessionId: string,
  accessToken: string,
  refreshToken?: string
): Promise<PostBookingSession> => {
  const headers = getAuthorizationHeader(accessToken, refreshToken);
  const rawSession = (await request({
    endpoint: `post-booking-session/${postBookingSessionId}`,
    method: "GET",
    headers,
  })) as unknown as PostBookingSession;

  return rawSession;
};

const updatePostBookingSessionEmail = async (
  email: string,
  accessToken: string,
  refreshToken?: string
): Promise<void> => {
  const headers = getAuthorizationHeader(accessToken, refreshToken);
  await request({
    endpoint: `post-booking-session/contact-email`,
    method: "POST",
    headers,
    body: { email },
  });

  return undefined;
};

export { getPostBookingSession, updatePostBookingSessionEmail };
