import React, { useContext } from "react";
import { PostBookingContext } from "../../context/PostBookingContext";
import Segment from "../../types/Segment";
import {
  AirlineInfoProps,
  FlightDetailsItemProps,
  FlightDirection,
  TripCardProps,
} from "../../types/TripCardTypes";
import { FormattedDateTime } from "./FormattedDateTime";
import airlines from "../../assets/airlines.json";
import { useTranslation } from "react-i18next";
import { FlightTakeoffIcon } from "../../assets/dynamic/FlightTakeoffIcon";
import { FlightLandIcon } from "../../assets/dynamic/FlightLandIcon";

export const TripCardItinerary = ({ direction }: TripCardProps) => {
  const { trip, getTimezoneFromAirport } = useContext(PostBookingContext); // Get PostBookingContext

  const journey =
    direction === FlightDirection.Depart ? trip.journeys[0] : trip.journeys[1];

  return (
    <div className="itinerary-details">
      {journey.segments.map((segment) => (
        <div key={segment.segmentId} className="mb-6 last:mb-0">
          <TripCardItineraryItem
            {...segment}
            departureAirportTimeZone={getTimezoneFromAirport(
              segment.departureAirport
            )}
            arrivalAirportTimeZone={getTimezoneFromAirport(
              segment.arrivalAirport
            )}
          />
        </div>
      ))}
    </div>
  );
};

const TripCardItineraryItem = ({
  arrivalAirport,
  arrivalTime,
  departureAirport,
  departureTime,
  operatingAirline,
  operatingFlightNumber,
  departureAirportTimeZone,
  arrivalAirportTimeZone,
}: Segment) => {
  return (
    <div className="itinerary-segment-details flex flex-row">
      <AirlineInfo
        airlineCode={operatingAirline}
        flightNumber={operatingFlightNumber}
      />
      <div>
        <FlightDetailsItem
          airportCode={departureAirport}
          dateTime={departureTime}
          timezone={departureAirportTimeZone}
          takeoff={true}
        />
        <FlightDetailsItem
          airportCode={arrivalAirport}
          dateTime={arrivalTime}
          timezone={arrivalAirportTimeZone}
          takeoff={false}
        />
      </div>
    </div>
  );
};

const AirlineInfo = ({ airlineCode, flightNumber }: AirlineInfoProps) => {
  const { t } = useTranslation();
  const airline = airlines[airlineCode];

  return (
    <div className="flex flex-col items-center mt-2">
      <div className="bg-partner-accent3 text-xs font-medium text-partner-textColorAccent px-1 py-0.5 rounded">
        {airlineCode} {flightNumber}
      </div>
      <div className="text-neutral-550 text-xs font-light w-20 text-center pt-1">
        {t("operated-by", { airline })}
      </div>
    </div>
  );
};

const FlightDetailsItem = ({
  airportCode,
  dateTime,
  takeoff,
  timezone,
}: FlightDetailsItemProps) => {
  const { trip, airportsData } = useContext(PostBookingContext); // Get PostBookingContext

  const fill = !takeoff ? "bg-partner-accent4" : "";
  return (
    <div className="flex flex-row pl-3 mb-6 last:mb-0">
      <div>
        <div
          className={`h-2 min-w-[8px] rounded border border-partner-accent ${fill} mr-4 mt-2`}
        />
        {takeoff ? (
          <div className="dashed-line min-h-[113%] overflow-hidden w-[1px] ml-1 mt-1 border-partner-accent" />
        ) : (
          <div />
        )}
      </div>
      <div className="flex flex-col">
        <p className="text-partner-textColorAccent">
          {airportsData[airportCode.toLowerCase()].city.name}{" "}
          {`(${airportCode})`}
        </p>
        <div className="flex flex-row mt-1">
          <div className="mr-2">
            {takeoff ? <FlightTakeoffIcon /> : <FlightLandIcon />}
          </div>
          <FormattedDateTime
            dateTime={dateTime}
            timezone={timezone}
            language={trip.language}
          />
        </div>
      </div>
    </div>
  );
};
