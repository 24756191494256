import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const PersonIcon = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1002_3214)">
        <path
          d="M8.00002 7.99984C9.47335 7.99984 10.6667 6.8065 10.6667 5.33317C10.6667 3.85984 9.47335 2.6665 8.00002 2.6665C6.52669 2.6665 5.33335 3.85984 5.33335 5.33317C5.33335 6.8065 6.52669 7.99984 8.00002 7.99984ZM8.00002 9.33317C6.22002 9.33317 2.66669 10.2265 2.66669 11.9998V13.3332H13.3334V11.9998C13.3334 10.2265 9.78002 9.33317 8.00002 9.33317Z"
          fill={colors.primary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1002_3214">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
