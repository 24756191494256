import React, { createContext, useRef, useState } from "react";
import Basket, {
  JourneyProductsSummary,
  SelectedProductSummary,
} from "../types/Basket";
import Journey from "../types/Journey";
import Order from "../types/Order";
import Passenger from "../types/Passenger";
import { FlightDirection } from "../types/TripCardTypes";
import toCamelCase from "../utils/toCamelCase";

// Below is what the BasketContext provides to it's children
interface BasketContextInterface {
  isBasketEmpty: boolean;
  isBasketValid: boolean;
  initializeBasketSummary: (
    basket: Record<string, Basket> | Record<string, Order>,
    journeys: Journey[],
    passengers: Passenger[]
  ) => void;
  updateBasketSummary: (
    basket: Record<string, Basket> | Record<string, Order>
  ) => void;
  getCurrency: () => string;
  getSelectedJourneyProductsSummary: (
    direction: FlightDirection
  ) => Record<"seat" | "bag", SelectedProductSummary>;
  getSelectedPassengerProductsSummary: (
    direction: FlightDirection,
    passengerId: string
  ) => Record<"seat" | "bag", SelectedProductSummary>;
  getTotalCost: () => number;
}

export const BasketContext = createContext<BasketContextInterface | null>(null); // Boilerplate

// Boilerplate
interface BasketProviderProps {
  children: JSX.Element;
}

export const BasketProvider = ({ children }: BasketProviderProps) => {
  const [basketSummary, setBasketSummary] = useState(
    [] as JourneyProductsSummary[]
  );
  const [isBasketEmpty, setIsBasketEmpty] = useState(true);
  const [isBasketValid, setIsBasketValid] = useState(false);
  const journeysRef = useRef([] as Journey[]);
  const passengersRef = useRef([] as Passenger[]);
  const currencyRef = useRef("USD");

  const initializeBasketSummary = (
    basket: Record<string, Basket> | Record<string, Order>,
    journeys: Journey[],
    passengers: Passenger[]
  ) => {
    journeysRef.current = journeys;
    passengersRef.current = passengers;
    const segmentsMap = formatSegments(journeys);
    const basketProductsSummary: JourneyProductsSummary[] =
      initializeBasketSummaryObject(journeys, passengers);
    const basketValidity = Object.values(basket).every((basketItem) => {
      return basketItem.validity.status === "valid";
    });
    setIsBasketValid(basketValidity);
    if (Object.keys(basket).length > 0) {
      updateBasketSummaryValues(
        basketProductsSummary,
        basket,
        segmentsMap,
        currencyRef
      );
      setIsBasketEmpty(false);
    } else {
      setIsBasketEmpty(true);
    }
    setBasketSummary(basketProductsSummary);
  };

  // Updates the displayed order summary information
  const updateBasketSummary = (
    basket: Record<string, Basket> | Record<string, Order>
  ) => {
    initializeBasketSummary(basket, journeysRef.current, passengersRef.current);
  };

  const getCurrency = () => {
    return currencyRef.current;
  };

  // Returns selected products for journey or passenger
  const getSelectedJourneyProductsSummary = (direction: FlightDirection) => {
    const journeyIndex = direction === FlightDirection.Depart ? 0 : 1;
    return basketSummary[journeyIndex].journeyProducts;
  };

  const getSelectedPassengerProductsSummary = (
    direction: FlightDirection,
    passengerId: string
  ) => {
    const journeyIndex = direction === FlightDirection.Depart ? 0 : 1;
    return basketSummary[journeyIndex].passengerProducts[passengerId];
  };

  // Returns total summed cost
  const getTotalCost = () => {
    let totalCost = 0;
    for (let i = 0; i < basketSummary.length; i++) {
      const journeyProductsSummary = basketSummary[i];
      totalCost += journeyProductsSummary.journeyProducts.seat.price;
      totalCost += journeyProductsSummary.journeyProducts.bag.price;
    }
    return totalCost;
  };

  return (
    <BasketContext.Provider
      value={{
        isBasketEmpty,
        isBasketValid,
        initializeBasketSummary,
        updateBasketSummary,
        getCurrency,
        getSelectedJourneyProductsSummary,
        getSelectedPassengerProductsSummary,
        getTotalCost,
      }}
    >
      {children}
    </BasketContext.Provider>
  );
};

//////////////////////////////////////////////////
////////////// Helper Functions //////////////////

const initializeBasketSummaryObject = (
  journeys: Journey[],
  passengers: Passenger[]
) => {
  const basketProductsSummary: JourneyProductsSummary[] = [];
  for (let i = 0; i < journeys.length; i++) {
    const passengerProds: Record<
      string,
      Record<"seat" | "bag", SelectedProductSummary>
    > = {};
    passengers.forEach((passenger) => {
      passengerProds[passenger.passengerId] = {
        seat: new SelectedProductSummary(
          new Map<string, string>(
            journeys[i]["segments"].map(
              (x) => [x["segmentId"], "N/A"] as [string, string]
            )
          )
        ),
        bag: new SelectedProductSummary(
          new Map<string, string>(
            journeys[i]["segments"].map(
              (x) => [x["segmentId"], "N/A"] as [string, string]
            )
          )
        ),
      };
    });
    const journeyProductsSummary: JourneyProductsSummary = {
      journeyProducts: {
        seat: new SelectedProductSummary(
          new Map<string, string>(
            journeys[i]["segments"]
              .flatMap((d) =>
                passengers.map((v) => d["segmentId"] + v.passengerId)
              )
              .map((x) => [x, "N/A"] as [string, string])
          )
        ),
        bag: new SelectedProductSummary(
          new Map<string, string>(
            journeys[i]["segments"]
              .flatMap((d) =>
                passengers.map((v) => d["segmentId"] + v.passengerId)
              )
              .map((x) => [x, "N/A"] as [string, string])
          )
        ),
      },
      passengerProducts: passengerProds,
    };
    basketProductsSummary[i] = journeyProductsSummary;
  }
  return basketProductsSummary;
};

const updateBasketSummaryValues = (
  basketProductsSummary: JourneyProductsSummary[],
  basket: Record<string, Basket> | Record<string, Order>,
  segmentsMap: Record<string, number>,
  currencyRef: React.MutableRefObject<string>
) => {
  for (const key in basket) {
    const item = toCamelCase(basket[key]) as unknown as Basket | Order;
    if (item.status === "cancelled") {
      continue;
    }
    const { productDetails, productType } = item;
    const { passengerId, segmentId } = productDetails;
    const journeyIndex = segmentsMap[segmentId];
    const { journeyProducts, passengerProducts } =
      basketProductsSummary[journeyIndex];

    const passengerProductInfo = passengerProducts[passengerId][productType];
    const journeyProductInfo = journeyProducts[productType];
    appendToSummaryProducts(passengerProductInfo, item, segmentId, currencyRef);
    appendToSummaryProducts(
      journeyProductInfo,
      item,
      segmentId + passengerId,
      currencyRef
    );
  }
};

const appendToSummaryProducts = (
  selectedProductSummary: SelectedProductSummary,
  basketItem: Basket | Order,
  basketIndex: string,
  currencyRef: React.MutableRefObject<string>
) => {
  const { productDetails, quantity, price } = basketItem;
  const { amount, decimalPlaces, currency } = price.total;
  const { column, row } = productDetails;
  const totalPrice = amount / 10 ** decimalPlaces;
  if (currency !== currencyRef.current) {
    currencyRef.current = currency;
  }
  selectedProductSummary.displayNames.set(basketIndex, row + column);
  selectedProductSummary.quantity += quantity;
  selectedProductSummary.price += totalPrice;
};

const formatSegments = (journeys: Journey[]) => {
  const newSegmentsMap: Record<string, number> = {};
  journeys.forEach((journey, index) => {
    journey.segments.forEach((segment) => {
      newSegmentsMap[segment.segmentId] = index;
    });
  });
  return newSegmentsMap;
};
