import React, { useContext } from "react";
import { SelectionInfoItemProps } from "../../types/TripCardTypes";
import { PostBookingContext } from "../../context/PostBookingContext";
import { SelectedProductSummary } from "../../types/Basket";
import { useTranslation } from "react-i18next";
import { SeatIcon } from "../../assets/dynamic/SeatIcon";
import { LuggageIcon } from "../../assets/dynamic/LuggageIcon";

interface SelectionInfoProps {
  products: Record<"seat" | "bag", SelectedProductSummary>;
  currency: string;
}

export const SelectionInfo = ({ products, currency }: SelectionInfoProps) => {
  const { trip, seat, bag } = useContext(PostBookingContext);
  return (
    <div className="selection-info-row flex flex-col">
      {seat && (
        <SelectionInfoItem
          productType={"seat"}
          language={trip.language}
          currency={currency}
          {...products.seat}
        />
      )}
      {bag && (
        <SelectionInfoItem
          productType={"bag"}
          language={trip.language}
          currency={currency}
          {...products.bag}
        />
      )}
    </div>
  );
};

const SelectionInfoItem = ({
  productType,
  quantity,
  currency,
  price,
  language,
  displayNames,
}: SelectionInfoItemProps) => {
  const { t } = useTranslation();
  const cost = new Intl.NumberFormat(language, {
    style: "currency",
    currency: currency,
  }).format(price);
  const displayValues = Array.from(displayNames.values());
  return (
    <div className="flex flex-row justify-between text-xs text-neutral-550 mt-2 first:mt-0">
      <div className="flex flex-row">
        {productType === "seat" ? <SeatIcon /> : <LuggageIcon />}
        <p className="pl-1">
          {quantity > 1
            ? t(`multi-${productType}s-selected`, { quantity })
            : quantity === 1
            ? t(`single-${productType}-selected`)
            : t(`no-${productType}s`)}
          {displayValues.length > 0 &&
            !displayValues.every((value: string) => value === "N/A") &&
            " - " +
              Array.from(displayNames.values()).reduce(
                (prev: string, curr: string, index: number) => {
                  if (index > 2) {
                    if (index === 3) {
                      return prev + "...";
                    }
                    return prev;
                  }
                  return prev + ", " + curr;
                }
              )}
        </p>
      </div>
      <p>{quantity > 0 ? cost : ""}</p>
    </div>
  );
};
