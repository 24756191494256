export const getPathParam = (path: string, paramNum: number) => {
  try {
    const res = path.split("/")[paramNum].split("?")[0];
    return res;
  } catch (e) {
    return undefined;
  }
};

export const getSessionId = (path: string) => getPathParam(path, 2);
