import React from "react";
import { AuthProvider } from "../context/AuthContext";
import { BasketProvider } from "../context/BasketContext";
import { PaymentStatusProvider } from "../context/PaymentStatusContext";
import { PostBookingProvider } from "../context/PostBookingContext";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  return (
    <AuthProvider>
      <PaymentStatusProvider>
        <BasketProvider>
          <PostBookingProvider>{children}</PostBookingProvider>
        </BasketProvider>
      </PaymentStatusProvider>
    </AuthProvider>
  );
};
