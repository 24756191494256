import React from "react";
import { useTranslation } from "react-i18next";

export const EmailField = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full mt-3">
      <div
        id="email"
        className="flex flex-col text-partner-textColorAccent font-light"
      >
        <label className="mb-1">{t("email")}</label>
        <input
          type="email"
          name="email"
          id="Field-emailInput"
          placeholder="you@email.com"
          className="p-4 bg-stripe-color rounded-[10px] placeholder-stripe-textColor outline-none border-none focus:border-partner-primary"
        />
      </div>
    </div>
  );
};
