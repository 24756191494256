import React, { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPostBookingSession } from "../api/postBookingSessionCall";
import { BasketContext } from "./BasketContext";
import { ErrorContext } from "./ErrorContext";
import Basket from "../types/Basket";
import Journey from "../types/Journey";
import {
  JourneyCityInfo,
  PostBookingSession,
} from "../types/PostBookingSession";
import { initSDK, loadSDK } from "../utils/gordianUtils";
import { getSessionId } from "../utils/urlUtils";
import { logError } from "../utils/logger";
import { AuthContext } from "./AuthContext";
import { loadStripe, Stripe } from "@stripe/stripe-js";

// Below is what PostBookingContext provides to it's children in addition to the PostBookingSession interface
interface PostBookingContextInterface extends PostBookingSession {
  getDestinationCityName: () => string;
  toggleOpenUpsell: () => void;
  getJourneyCityInfo: (journey: Journey) => JourneyCityInfo;
  getTimezoneFromAirport: (airportCode: string) => string;
  partnerName: string;
  stripeTestPromise: Promise<Stripe>;
  stripeLivePromise: Promise<Stripe>;
}

export const PostBookingContext =
  createContext<PostBookingContextInterface | null>(null); // Boilerplate

// Boilerplate
interface PostBookingProviderProps {
  children: JSX.Element | React.ReactNode;
}

const loadGordianStripe = (partnerName: string) => {
  if (partnerName == "tui") {
    return "acct_1MsuV1RfQDiSTUGB";
  } else if (partnerName == "tui_prod") {
    return "acct_1L2cZvBXUi6Q2YrG";
  } else if (partnerName == "firefly_whitelabel_sandbox_demo") {
    return "acct_1MsuV1RfQDiSTUGB";
  }
  return undefined;
};

export const PostBookingProvider = ({ children }: PostBookingProviderProps) => {
  const { initializeBasketSummary, updateBasketSummary } =
    useContext(BasketContext);
  const { setError } = useContext(ErrorContext);
  const [postBookingSession, setPostBookingSession] = useState(
    {} as PostBookingSession
  );
  const auth = useContext(AuthContext);
  const { i18n } = useTranslation();

  // Toggles the slideover animation in GordianWidget.tsx
  const toggleOpenUpsell = () => {
    document
      .getElementById("slideover-container")
      .classList.toggle("invisible");
    document.getElementById("slideover-bg").classList.toggle("opacity-0");
    document.getElementById("slideover-bg").classList.toggle("opacity-50");
    document.getElementById("slideover").classList.toggle("translate-x-full");
  };

  // Returns the final destination city name
  const getDestinationCityName = () => {
    const { airportsData, trip } = postBookingSession;
    if (airportsData && trip) {
      const segments = trip.journeys[0]?.segments;
      const destinationAirport = segments[segments.length - 1].arrivalAirport;
      return airportsData[destinationAirport.toLowerCase()].city.name;
    } else {
      return "...";
    }
  };

  // Gets city and airport info for a Journey
  const getJourneyCityInfo = (journey: Journey): JourneyCityInfo => {
    const { airportsData } = postBookingSession;
    const startAirport = journey.segments[0].departureAirport;
    const endAirport =
      journey.segments[journey.segments.length - 1].arrivalAirport;
    const startCity = airportsData[startAirport.toLowerCase()].city.name;
    const endCity = airportsData[endAirport.toLowerCase()].city.name;

    const startAirportTimeZone =
      airportsData[startAirport.toLowerCase()].timezone;
    const endAirportTimeZone = airportsData[endAirport.toLowerCase()].timezone;
    return {
      startCity,
      endCity,
      startAirport,
      endAirport,
      startAirportTimeZone,
      endAirportTimeZone,
    };
  };

  const getTimezoneFromAirport = (airportCode: string): string => {
    const { airportsData } = postBookingSession;
    return airportsData[airportCode.toLowerCase()].timezone;
  };

  const onBasketChange = async ({
    basket,
  }: {
    basket: Record<string, Basket>;
  }) => {
    try {
      toggleOpenUpsell();
      updateBasketSummary(basket);
    } catch (e) {
      logError(null, e);
    }
  };

  // Loads the Gordian SDK and PostBookingSession on app init
  const loadSession = async () => {
    const sessionId = getSessionId(window.location.pathname);
    try {
      const response = await getPostBookingSession(
        sessionId,
        auth.accessToken,
        auth.refreshToken
      );
      const {
        tripId,
        tripAccessToken,
        basket,
        orders,
        journeys,
        passengers,
        language,
      } = response.trip;
      const summary = Object.keys(orders).length > 0 ? orders : basket;
      initializeBasketSummary(summary, journeys, passengers);
      initSDK(
        tripId,
        tripAccessToken,
        onBasketChange,
        setError,
        updateBasketSummary
      );
      window.sdkInitialized = true;
      i18n.changeLanguage(language);
      setPostBookingSession(response);
    } catch (error) {
      if (error.name === "AxiosError") {
        setError("404");
      } else {
        setError("generic");
      }
    }
  };

  useEffect(() => {
    // Load SDK script and pass in callback function to load session
    loadSDK(() => {
      loadSession();
    });
  }, []);

  let stripeTestPromise = undefined;
  let stripeLivePromise = undefined;
  const partnerName = postBookingSession.partnerName;
  const connectedAccountID = loadGordianStripe(partnerName);
  if (connectedAccountID != undefined) {
    stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST, {
      stripeAccount: connectedAccountID,
    });
    stripeLivePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, {
      stripeAccount: connectedAccountID,
    });
  } else {
    stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY_TEST);
    stripeLivePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  }

  return (
    <PostBookingContext.Provider
      value={{
        ...postBookingSession,
        getDestinationCityName,
        toggleOpenUpsell,
        getJourneyCityInfo,
        getTimezoneFromAirport,
        partnerName,
        stripeTestPromise,
        stripeLivePromise,
      }}
    >
      {children}
    </PostBookingContext.Provider>
  );
};
