import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const FlightTakeoffIcon = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_702_7911)">
        <path
          d="M1.66665 12.6665H14.3333V13.9999H1.66665V12.6665ZM14.7133 6.42655C14.5733 5.89322 14.02 5.57322 13.4867 5.71988L9.94665 6.66655L5.34665 2.37988L4.05999 2.71988L6.81999 7.49988L3.50665 8.38655L2.19332 7.35988L1.22665 7.61988L2.43999 9.72655L2.95332 10.6132L4.01999 10.3265L7.55999 9.37988L10.46 8.60655L14 7.65988C14.54 7.50655 14.8533 6.95988 14.7133 6.42655Z"
          fill={colors.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_702_7911">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
