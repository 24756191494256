/* eslint-disable max-len */
import axios, { AxiosRequestConfig, Method } from "axios";
import toCamelCase from "../utils/toCamelCase";
import { log } from "../utils/logger";
const BASE_URL = process.env.REACT_APP_API_URL;

const httpClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

interface RequestInterface {
  endpoint: string;
  method: Method;
  body?: Record<string, unknown>;
  params?: Record<string, unknown>;
  headers?: Record<string, string>;
}

httpClient.interceptors.response.use(
  (res) => toCamelCase(res),
  (error) => Promise.reject(toCamelCase(error))
);

const request = async ({
  endpoint,
  method,
  body,
  params,
  headers,
}: RequestInterface): Promise<Record<string, never>> => {
  try {
    const config: AxiosRequestConfig = {
      url: `${BASE_URL}/${endpoint}`,
      method,
      params,
      data: body,
      headers,
    };

    const response = await httpClient(config);
    return response.data;
  } catch (error) {
    log(error);
    throw error; // Rethrow error for error handling at component or React Context level
  }
};

export const getAuthorizationHeader = (
  accessToken: string,
  // TODO: we need use refresh token somehow
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshToken?: string
) => {
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export default request;
