import React, { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance } from "@stripe/stripe-js";
import { getStripePaymentIntentSecret } from "../../api/payments";
import { CheckoutForm } from "./CheckoutForm";
import { ErrorContext } from "../../context/ErrorContext";
import { PaymentStatusContext } from "../../context/PaymentStatusContext";
import { AuthContext } from "../../context/AuthContext";
import { PostBookingContext } from "../../context/PostBookingContext";

const fonts = [
  {
    cssSrc: "https://fonts.googleapis.com/css?family=Inter",
  },
];

interface PaymentsProps {
  sessionId: string;
}

export const Payments = ({}: PaymentsProps) => {
  const { setError } = useContext(ErrorContext);
  const { paymentStatus } = useContext(PaymentStatusContext);
  const { stripeTestPromise, stripeLivePromise } =
    useContext(PostBookingContext);
  const [clientSecret, setClientSecret] = useState("");
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (
      paymentStatus.allowCheckout &&
      paymentStatus.paymentGateway === "stripe" &&
      auth.accessToken
    ) {
      getStripePaymentIntentSecret(auth.accessToken, auth.refreshToken)
        .then((res) => setClientSecret(res.paymentIntentClientSecret))
        .catch(() => setError("generic"));
    }
  }, [paymentStatus, auth.accessToken, auth.refreshToken]);

  const styles = getComputedStyle(document.body);
  const appearance: Appearance = {
    theme: "flat",
    variables: {
      colorText: `${styles.getPropertyValue("--color-accent4")}`,
      fontFamily: "Inter",
      borderRadius: "10px",
    },
  };

  return (
    <div className="mt-10 mx-0.5 pb-6">
      {paymentStatus.paymentGateway === "stripe" &&
        stripeLivePromise &&
        stripeTestPromise &&
        clientSecret && (
          <Elements
            stripe={
              paymentStatus.isSandbox ? stripeTestPromise : stripeLivePromise
            }
            options={{ clientSecret, appearance, fonts }}
          >
            <CheckoutForm />
          </Elements>
        )}
    </div>
  );
};
