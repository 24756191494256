import React, { useContext } from "react";
import { CustomizationContext } from "../../context/CustomizationContext";

export const SeatIcon = () => {
  const { colors } = useContext(CustomizationContext);

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1003_3171)">
        <path
          d="M10.6666 12.666H5.95325C4.96659 12.666 4.12659 11.946 3.97992 10.9727L2.66659 4.66602H1.33325L2.65992 11.1727C2.91325 12.7993 4.31325 13.9993 5.95992 13.9993H10.6666V12.666Z"
          fill={colors.secondary}
        />
        <path
          d="M3.56674 3.76024C2.96674 3.33357 2.82007 2.5069 3.24007 1.90024C3.66007 1.30024 4.49341 1.15357 5.10007 1.57357C5.70007 2.00024 5.84674 2.8269 5.42674 3.43357C5.00007 4.03357 4.17341 4.18024 3.56674 3.76024ZM10.8201 10.0002H7.56674L6.88007 7.2669C7.93341 7.86024 9.06674 8.29357 10.3134 8.08024V6.66024C9.22674 6.8669 8.02007 6.48024 7.18674 5.8269L6.09341 4.98024C5.94007 4.86024 5.76674 4.78024 5.58674 4.7269C5.37341 4.6669 5.14674 4.6469 4.92674 4.6869H4.91341C4.09341 4.83357 3.54674 5.61357 3.68674 6.4269L4.58674 10.3736C4.77341 11.3202 5.59341 12.0002 6.55341 12.0002H11.1201L13.6667 14.0002L14.6667 13.0002L10.8201 10.0002Z"
          fill={colors.secondary}
        />
      </g>
      <defs>
        <clipPath id="clip0_1003_3171">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
