import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CustomizationContext } from "../../context/CustomizationContext";
import { Button } from "../Common/Button";

export const HelpButtons = () => {
  const { partnerUrls } = useContext(CustomizationContext);
  const { t } = useTranslation();

  const onRedirectClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    // TODO: Use dynamic urls from backend to determine where to redirect user
    e.preventDefault();
    if (e.currentTarget.id === "go-home" && partnerUrls.returnUrl) {
      window.location.replace(partnerUrls.returnUrl);
    } else if (e.currentTarget.id === "go-support" && partnerUrls.supportUrl) {
      window.location.replace(partnerUrls.supportUrl);
    }
  };

  return (
    <div className="flex flex-row gap-2">
      <Button
        id="go-home"
        buttonText={t("go-home")}
        onClick={onRedirectClick}
      />
      <Button
        id="go-support"
        buttonText={t("contact-support")}
        onClick={onRedirectClick}
      />
    </div>
  );
};
