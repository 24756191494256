import React, { useContext } from "react";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import {
  CardTopRowProps,
  FlightDirection,
  TripCardHeaderProps,
} from "../../types/TripCardTypes";

import { TripCardItinerary } from "./TripCardItinerary";
import { FormattedDateTime } from "./FormattedDateTime";
import { PostBookingContext } from "../../context/PostBookingContext";
import { PersonIcon } from "../../assets/dynamic/PersonIcon";

export const TripCardHeader = ({
  direction,
  isCardExpanded,
  onCardTopClick,
}: TripCardHeaderProps) => {
  const { trip, getJourneyCityInfo } = useContext(PostBookingContext); // Get PostBookingContext
  const journey =
    direction === FlightDirection.Depart ? trip.journeys[0] : trip.journeys[1];

  // Get city names
  const { startCity, endCity, startAirportTimeZone } =
    getJourneyCityInfo(journey);

  // Get departure time
  const departureDateTime = journey.segments[0].departureTime;

  // Get passenger quantity
  const passengerNum = trip.passengers.length;

  return (
    <div className="trip-header px-4 pt-4">
      <CardTopRow
        isCardExpanded={isCardExpanded}
        onCardTopClick={onCardTopClick}
        dateTime={departureDateTime}
        passengerNum={passengerNum}
        timezone={startAirportTimeZone}
      />
      <div className="trip-journey-text text-partner-textColor font-medium flex flex-row pb-4">
        {startCity}
        <ArrowLongRightIcon className="w-6 mx-2 fill-partner-secondary" />
        {endCity}
      </div>
      {isCardExpanded && <TripCardItinerary direction={direction} />}
    </div>
  );
};

const CardTopRow = ({
  isCardExpanded,
  onCardTopClick,
  dateTime,
  passengerNum,
  timezone,
}: CardTopRowProps) => {
  const { trip } = useContext(PostBookingContext);
  return (
    <div
      className="flex flex-row justify-between items-center pb-2"
      onClick={onCardTopClick}
    >
      <FormattedDateTime
        dateTime={dateTime}
        timezone={timezone}
        language={trip.language}
      />
      <div className="flex flex-row w-1/5 justify-end">
        <div className="passenger-num-indicator flex flex-row w-3/5 items-center">
          <PersonIcon />
          <p className="text-partner-textColor text-xs font-medium">
            {passengerNum}
          </p>
        </div>
        <div className="h-6 w-6">
          {isCardExpanded ? (
            <ChevronUpIcon className="fill-partner-secondary" />
          ) : (
            <ChevronDownIcon className="fill-partner-secondary" />
          )}
        </div>
      </div>
    </div>
  );
};
