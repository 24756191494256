import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CustomizationContext } from "../../context/CustomizationContext";
import { PostBookingContext } from "../../context/PostBookingContext";
import Swal from "sweetalert2";

export const Footer = () => {
  const { t } = useTranslation();
  const { tosUrl } = useContext(CustomizationContext);
  const { colors } = useContext(CustomizationContext);
  const { partnerName } = useContext(PostBookingContext);

  let faqs = [];

  if (partnerName !== "") {
    faqs = [
      {
        category: t("documents-" + partnerName),
        questions: [
          {
            question: t("documents-question-" + partnerName),
            answer: t("documents-answer-" + partnerName),
          },
        ],
      },
      {
        category: t("transfer-" + partnerName),
        questions: [
          {
            question: t("transfer-question-" + partnerName),
            answer: t("transfer-answer-" + partnerName),
          },
        ],
      },
      {
        category: t("cancellation-" + partnerName),
        questions: [
          {
            question: t("cancellation-question-" + partnerName),
            answer: t("cancellation-answer-" + partnerName),
          },
        ],
      },
      {
        category: t("flight-change-" + partnerName),
        questions: [
          {
            question: t("flight-change-question-" + partnerName),
            answer: t("flight-change-answer-" + partnerName),
          },
        ],
      },
      {
        category: t("payment-" + partnerName),
        questions: [
          {
            question: t("payment-question-" + partnerName),
            answer: t("payment-answer-" + partnerName),
          },
          {
            question: t("payment-question-2-" + partnerName),
            answer: t("payment-answer-2-" + partnerName),
          },
          {
            question: t("payment-question-3-" + partnerName),
            answer: t("payment-answer-3-" + partnerName),
          },
        ],
      },
      {
        category: t("ticketing-" + partnerName),
        questions: [
          {
            question: t("ticketing-question-" + partnerName),
            answer: t("ticketing-answer-" + partnerName),
          },
        ],
      },
      {
        category: t("seat-reservation-" + partnerName),
        questions: [
          {
            question: t("seat-reservation-question-" + partnerName),
            answer: t("seat-reservation-answer-" + partnerName),
          },
          {
            question: t("seat-reservation-question-2-" + partnerName),
            answer: t("seat-reservation-answer-2-" + partnerName),
          },
        ],
      },
      {
        category: t("claim-" + partnerName),
        questions: [
          {
            question: t("claim-question-" + partnerName),
            answer: t("claim-answer-" + partnerName),
          },
        ],
      },
    ];
  }

  const showFaqModal = () => {
    const faqHtml = faqs
      .map(
        (faq) => `
      <div>
        <h3 style="font-size: 20px; font-weight: bold; color: ${
          colors.primary
        }">${faq.category}</h3>
        ${faq.questions
          .map(
            (q) => `
          <div>
            <h4 style="font-size: 18px; font-weight: bold; color: ${colors.secondary}">${q.question}</h4>
            <p style="font-size: 16px; color: ${colors.textColor}">${q.answer}</p>
          </div>
        `
          )
          .join("")}
      </div><br>
    `
      )
      .join("");

    Swal.fire({
      title: t("frequently-asked-questions"),
      html: faqHtml,
      confirmButtonText: t("close"),
      confirmButtonColor: colors.primary,
      width: 800,
    });
  };

  return (
    <footer className="bottom-0 pt-5 border-t border-gray-100">
      <ul className="flex flex-row-reverse justify-between mt-3 text-sm text-gray-400">
        <ul className="flex flex-col justify-between">
          <h1 className="text-xs max-w-[570px]">
            {" "}
            {t("ancillaries-booked-disclaimer")}{" "}
            {t("ancillaries-refund-disclaimer")}{" "}
          </h1>
        </ul>
        <h1 className="cursor-pointer">
          {" "}
          <a
            onClick={partnerName ? showFaqModal : null}
            style={{ cursor: partnerName ? "pointer" : "default" }}
          >
            {t("frequently-asked-questions")}
          </a>{" "}
          <br></br>
          <a href={tosUrl} download target="_blank" rel="noopener noreferrer">
            {t("terms-and-conditions")}
          </a>
        </h1>
      </ul>
    </footer>
  );
};
