import React, { createContext, useState } from "react";

// Below is what ErrorContext provides to it's children
interface ErrorContextInterface {
  errorType: ErrorType;
  setError: (errorType: ErrorType) => void;
}

export type ErrorType =
  | "404"
  | "seatLoadFailed"
  | "bagLoadFailed"
  | "generic"
  | "";

export const ErrorContext = createContext<ErrorContextInterface | null>(null);

interface ErrorProviderProps {
  children: JSX.Element;
}

export const ErrorProvider = ({ children }: ErrorProviderProps) => {
  const [errorType, setErrorType] = useState("" as ErrorType);

  const setError = (errorType: ErrorType) => {
    setErrorType(errorType);
  };

  return (
    <ErrorContext.Provider
      value={{
        errorType,
        setError,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};
