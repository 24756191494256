import request from "./httpClient";
import { AuthorizeApiRes, AuthStatus } from "../types/Authorize";

export const authorize = async (
  sessionIdEncoded: string,
  pnr: string,
  lastname: string
) => {
  const res = await request({
    endpoint: `/authorize/${sessionIdEncoded}`,
    method: "POST",
    body: {
      pnr,
      lastname,
    },
  }).catch(() => {
    return {
      status: "not_authenticated" as AuthStatus,
    };
  });

  return res as unknown as AuthorizeApiRes;
};
