import request from "./httpClient";

export interface PartnerCustomization {
  primaryColor: string;
  secondaryColor: string;
  accent1: string;
  accent2: string;
  accent3: string;
  accent4: string;
  textColor: string;
  font: string;
  logo: string;
  returnUrl: string;
  supportUrl: string;
  language: string;
  faviconPath: string;
  tosPath: string;
}

export const getPartnerCustomization = async (
  sessionIdEncoded: string
): Promise<PartnerCustomization> => {
  const response = (await request({
    endpoint: `post-booking-session/get-partner-customisations/${sessionIdEncoded}`,
    method: "GET",
  })) as unknown as PartnerCustomization;
  return response;
};
